import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { TheaterSeatGroup, TheaterSeat, Theater } from "../theater/seat";
import { Attendee, useAttendee } from "../../hooks/contexts/UseAttendeeContext";
import { WelcomeStep } from "./welcome-step";
import { ChooseSeatStep } from "./ChooseSeat/choose-seat-step";
import QRCode from "react-qr-code";
import { Container } from "@mui/material";
import { TicketsStep } from "./tickets-step";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import StepperContext from "../../hooks/contexts/StepperContext";
import {
	useStepper,
	useStepperContext,
} from "../../hooks/contexts/useStepperContext ";
import { ChooseSeatStepperButton } from "./ChooseSeat/choose-seat-step-button";
import { useSeatsContext } from "../../hooks/contexts/useSeatsContext";
import { useEffect, useState } from "react";
import { DownloadTicketsButton } from "./ChooseSeat/downloan-tickets-button";
import { makeRequest } from "../../utility/request";
import { useEventContext } from "../../hooks/contexts/UseEventContext";

export enum SeatType {
	HIDDEN = -1,
	BLOCKED = 0,
	CLASS_A = 1,
	CLASS_B = 2,
	CLASS_C = 3,
	CLASS_D = 4,
}

export enum SeatStatus {
	FREE,
	RESERVED,
}

export class Seat {
	id: number;
	number: string;
	type: SeatType;
	status: SeatStatus;
	theater_id: number;

	constructor({
		id,
		number,
		type,
		status,
		theater_id,
	}: {
		id: number;
		number: string;
		type: SeatType;
		status: SeatStatus;
		theater_id: number;
	}) {
		this.id = id;
		this.number = number;
		this.type = type;
		this.status = status;
		this.theater_id = theater_id;
	}
}

export class SeatGroup {
	seats: Array<Seat>;

	constructor({ seats }: { seats: Array<Seat> }) {
		this.seats = seats;
	}
}

export class Row {
	seatGroups: Array<SeatGroup>;
	row_id: string;
	constructor({
		seatGroups,
		seat_row,
	}: {
		seatGroups: Array<SeatGroup>;
		seat_row: string;
	}) {
		this.seatGroups = seatGroups;
		this.row_id = seat_row;
	}
}

export class TheaterClass {
	rows: Array<Row>;

	constructor({ rows }: { rows: Array<Row> }) {
		this.rows = rows;
	}
}

export default function HorizontalNonLinearStepper() {
	const [steps, setSteps] = useState<Array<string>>([]);

	const { SeatsContext, selectedSeats, setSelectedSeats, reload, setReload } =
		useSeatsContext();
	const { attendee }: { attendee?: Attendee } = useAttendee();
	const { EventContext, event, setEvent } = useEventContext({
		event_id: attendee!.event_id,
	});

	const [activeStep, setActiveStep] = useState(0);
	const [completed, setCompleted] = useState<{
		[k: number]: boolean;
	}>({});

	const { StepperContext } = useStepperContext();

	useEffect(() => {
		if (attendee?.already_reserved) {
			setActiveStep(2);
		}
	}, [attendee]);

	useEffect(() => {
		if (event) {
			// // console.log(event?.name)
			setSteps([event?.name, "حجز المقاعد", "التذاكر"]);
		}
	}, [event]);

	const totalSteps = () => {
		return steps.length;
	};

	const completedSteps = () => {
		return Object.keys(completed).length;
	};

	const isLastStep = () => {
		return activeStep === totalSteps() - 1;
	};

	const allStepsCompleted = () => {
		return completedSteps() === totalSteps();
	};

	const handleNext = () => {
		const newActiveStep =
			isLastStep() && !allStepsCompleted()
				? // It's the last step, but not all steps have been completed,
				  // find the first step that has been completed
				  steps.findIndex((step, i) => !(i in completed))
				: activeStep + 1;
		setActiveStep(newActiveStep);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStep = (step: number) => () => {
		setActiveStep(step);
	};

	const handleComplete = () => {
		const newCompleted = completed;
		newCompleted[activeStep] = true;
		setCompleted(newCompleted);
		handleNext();
	};

	const handleReset = () => {
		setActiveStep(0);
		setCompleted({});
	};

	const handleAreaD = () => {
		makeRequest({
			endpoint: `/api/ticket/insert_d`,
			data: {
				attendee_id: attendee?.id,
				event_id: attendee?.event_id,
				seat_ids: [attendee?.number_of_tickets],
			},
			onResolve: (response: any) => {
				setActiveStep(2);
			},
			onCatch: (err: any) => {
				setReload!((prev: number) => prev + 1);
				setSelectedSeats!([]);
			},
			requestMessage: {
				pending: "بناكدلك الحجز",
				error: "ما زبط ارجع اكبس",
			},
		});
	};

	return (
		<StepperContext.Context.Provider
			value={{
				totalSteps: totalSteps,
				completedSteps: completedSteps,
				isLastStep: isLastStep,
				allStepsCompleted: allStepsCompleted,
				handleNext: handleNext,
				handleBack: handleBack,
				handleStep: handleStep,
				handleComplete: handleComplete,
				handleReset: handleReset,
			}}
		>
			<SeatsContext.Context.Provider
				value={{
					selectedSeats: selectedSeats,
					setSelectedSeats: setSelectedSeats,
					reload: reload,
					setReload: setReload,
				}}
			>
				<EventContext.Context.Provider
					value={{
						event: event,
						setEvent: setEvent,
					}}
				>
					<Box
						sx={{
							// width: "100%",
							// minHeight: "100vh",
							display: "flex",
							flexDirection: "column",
							padding: "20px",
							boxSizing: "border-box",
						}}
					>
						<Container maxWidth="md" sx={{ zIndex: 20, p: 0 }}>
							<Stepper
								alternativeLabel
								nonLinear
								activeStep={activeStep}
							>
								{steps.map((label, index) => (
									<Step
										key={label}
										completed={completed[index]}
									>
										<StepButton
											disabled
											color="inherit"
											// onClick={handleStep(index)}
										>
											{label}
										</StepButton>
									</Step>
								))}
							</Stepper>
						</Container>
						<br></br> <br></br>
						<br></br>
						<Box sx={{ padding: "0px", flexGrow: 1, zIndex: 22 }}>
							{activeStep === 0 ? (
								<WelcomeStep />
							) : activeStep === 1 ? (
								<ChooseSeatStep />
							) : activeStep === 2 ? (
								<TicketsStep />
							) : activeStep === 3 ? (
								3
							) : activeStep === 4 ? (
								4
							) : (
								<>Done</>
							)}
						</Box>
						{activeStep === 0 ? (
							<Box
								sx={{
									position: "fixed",
									bottom: "0",
									left: "0",
									width: "100%",
									height: "80px",
									zIndex: 30,
									direction: "rtl",
								}}
							>
								{/* <Typography sx={{ width: "100%", mt: "-20px" }}>
						اختر مقعدك
					</Typography> */}
								<Button
									variant="contained"
									size="large"
									onClick={() => {
										// if (attendee?.ticket_type == 4) {
										// 	handleAreaD();
										// } else {
											handleNext();
										// }
									}}
									sx={{ width: "100%", height: "100%" }}
								>
									اختر مقعدك
									{/* {selectedSeats.length} من {attendee?.number_of_tickets} */}
								</Button>
							</Box>
						) : activeStep === 1 ? (
							// <Box
							// 	sx={{
							// 		position: "fixed",
							// 		bottom: "0",
							// 		left: "0",
							// 		width: "100%",
							// 		height: "80px",
							// 		zIndex: 30,
							// 		direction: "rtl",
							// 	}}
							// >
							// 	{/* <Typography sx={{ width: "100%", mt: "-20px" }}>
							// 	اختر مقعدك
							// </Typography> */}
							// 	<Button
							// 		variant="contained"
							// 		size="large"
							// 		onClick={handleNext}
							// 		sx={{ width: "100%", height: "100%" }}
							// 	>
							// 		تاكيد الحجز
							// 		{/* {selectedSeats.length} من {attendee?.number_of_tickets} */}
							// 	</Button>
							// </Box>
							<ChooseSeatStepperButton />
						) : activeStep === 2 ? (
							<DownloadTicketsButton />
						) : // 	<Box
						// 	sx={{
						// 		position: "fixed",
						// 		bottom: "0",
						// 		left: "0",
						// 		width: "100%",
						// 		height: "80px",
						// 		zIndex: 30,
						// 		direction: "rtl",
						// 	}}
						// >
						// 	{/* <Typography sx={{ width: "100%", mt: "-20px" }}>
						// 		اختر مقعدك
						// 	</Typography> */}
						// 	<Button
						// 		variant="contained"

						// 		size="large"
						// 		onClick={() => {
						// 			var scaleBy = 2;
						// 			var w = 400;
						// 			var h = 800;
						// 			// var div = document.querySelector("#test");
						// 			// var canvas = document.createElement("canvas");
						// 			// // canvas.width = w * scaleBy;
						// 			// // canvas.height = h * scaleBy;
						// 			// canvas.style.width = w + "px";
						// 			// canvas.style.height = h + "px";
						// 			// var context: any = canvas.getContext("2d");
						// 			// context.scale(scaleBy, scaleBy);

						// 			const tickets = document.getElementsByClassName("ticket");
						// 			var pdf = new jsPDF("p", "px", [w, h]);

						// 			for (let i =0 ;i<tickets.length;i++){
						// 				let e:any = tickets[i];

						// 			// tickets.map((e:any)=>{
						// 				html2canvas(
						// 				e,

						// 				).then(function (canvas) {
						// 					// document.body.append(canvas);
						// 					var myImage = canvas.toDataURL("image/jpeg,1.0");

						// 					pdf.addPage()
						// 					pdf.addImage(myImage, "png", 0, 0, w, h);

						// 					// pdf.addImage(
						// 						// 	myImage,
						// 						// 	"png",
						// 						// 	0,
						// 						// 	0,
						// 						// 	w/2,
						// 						// 	h/2
						// 						// );
						// 					});
						// 				}
						// 			// })

						// 			// tickets.map((e:any)=>{

						// 			pdf.save(`وتر الشرق - مع الست - .pdf`);
						// 		}

						// 		}
						// 		sx={{ width: "100%", height: "100%" }}
						// 	>

						// 		حفط الجميع
						// 		{/* {selectedSeats.length} من {attendee?.number_of_tickets} */}
						// 	</Button>
						// </Box>
						activeStep === 3 ? (
							3
						) : activeStep === 4 ? (
							4
						) : (
							<>Done</>
						)}
					</Box>
				</EventContext.Context.Provider>
			</SeatsContext.Context.Provider>
		</StepperContext.Context.Provider>
	);
}
