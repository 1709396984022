import { useContext } from "react";
import StepperContext from "./StepperContext";


export function useStepperContext() {


    return {
		StepperContext: StepperContext,
	};
}

export function useStepper() {
	return useContext(StepperContext.Context);
}
