import { useContext, useEffect, useState } from "react";
import SeatsContext from "./SeatsContext";


export function useSeatsContext() {
	
	const [selectedSeats, setSelectedSeats] = useState<Array<number>>([]);
	const[reload,setReload] = useState(0);


    return {
		SeatsContext: SeatsContext,
		selectedSeats: selectedSeats, setSelectedSeats: setSelectedSeats ,
		reload:reload, setReload:setReload,
	};
}

export function useSeats() {
	return useContext(SeatsContext.Context);
}
