import {
	Box,
	Checkbox,
	Divider,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	InputAdornment,
	MenuItem,
	Radio,
	RadioGroup,
	Slider,
	Stack,
	Switch,
	SxProps,
	TextField,
	styled,
} from "@mui/material";
import {
	ChangeEventHandler,
	Dispatch,
	ReactElement,
	useEffect,
	useRef,
	useState,
} from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { UseFormRegister } from "react-hook-form";

import InputAttributes from "./classes/InputAttribures";
// export class InputAttributes {
// 	type: string;
// 	label: string;
// 	description: string;
// 	name: string;
// 	required: boolean;
// 	multiPartInput: any;
// 	onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | any;

// 	setValueHook:
// 		| ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
// 		| Dispatch<any>
// 		| any;
// 	setSectionValueHook:
// 		| ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
// 		| Dispatch<any>
// 		| any;
// 	endIcon: Array<ReactElement>;
// 	value: any;
// 	defaultValue: any;

// 	constructor({
// 		type = "text",
// 		label = "input",
// 		description = "",
// 		multiPartInput = [],
// 		name = "",
// 		required = false,
// 		onChange = () => {},
// 		setValueHook = () => {},
// 		setSectionValueHook = () => {},
// 		endIcon = [],
// 		value = "",
// 		defaultValue = "",
// 	}: {
// 		type?:
// 			| "number"
// 			| "password"
// 			| "url"
// 			| "text"
// 			| "tel"
// 			| "email"
// 			| "date"
// 			| "time"
// 			| "month"
// 			| "week"
// 			| "datetime-local"
// 			| "color"
// 			| "file"
// 			| "textarea"
// 			| "select"
// 			| "checkbox"
// 			| "switch"
// 			| "range"
// 			| "radio";
// 		label?: string;
// 		description?: string;
// 		multiPartInput?: any;
// 		name?: string;
// 		required?: boolean;
// 		onChange?:
// 			| ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
// 			| any;
// 		setValueHook?:
// 			| ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
// 			| Dispatch<any>
// 			| any;
// 		setSectionValueHook?:
// 			| ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
// 			| Dispatch<any>
// 			| any;
// 		endIcon?: Array<ReactElement>;
// 		value?: any;
// 		defaultValue?: any;
// 	}) {
// 		this.type = type;
// 		this.label = label;
// 		this.description = description;
// 		this.name = name;
// 		this.multiPartInput = multiPartInput;
// 		this.required = required;
// 		this.onChange = onChange;
// 		this.setValueHook = setValueHook;
// 		this.setSectionValueHook = setSectionValueHook;
// 		this.endIcon = endIcon;
// 		this.value = value;
// 		this.defaultValue = defaultValue;
// 	}
// }
import MuiPhoneNumber from "mui-phone-number";
import { useFormulator } from "./useForm/UseFormulatorContext";

const rtl_style = {
	"& .MuiInputLabel-formControl": {
		right: "30px !important",
		left: "unset !important",
	},
	"& .MuiInputLabel-root": {
		display: "block",
		"transform-origin": "top right !important",
	},
	"& .MuiInputLabel-shrink": {
		"transform-origin": "top right !important",
	},
	"& .MuiOutlinedInput-notchedOutline": {
		"text-align": "right!important",
	},
	"& .MuiSelect-icon": {
		right: "unset",
		left: "7px",
	},
	"& .MuiOutlinedInput-root": {
		pr: 0,
		pl: "14px",
	},
	"& .MuiOutlinedInput-input": {
		px: "14px",
	},

	"& .MuiFormHelperText-root": {
		textAlign: "initial",
	},
};
export function FormInput({
	inputRef,
	input = new InputAttributes({}),
	sx = {},
}: {
	inputRef: any;
	input?: InputAttributes;
	sx?: SxProps;
}) {
	switch (input.type) {
		case "number":
		case "password":
		case "url":
		case "text":
		case "email":
		case "time":
		case "month":
		case "week":
		case "datetime-local":
		case "color":
		case "file":
			return <FormInputText inputRef={inputRef} input={input} sx={sx} />;

		case "date":
			return <FormInputDate inputRef={inputRef} input={input} sx={sx} />;

		case "tel":
			return (
				<FormMobileNumberInput
					inputRef={inputRef}
					input={input}
					sx={sx}
				/>
			);
		case "textarea":
			return (
				<TextField
					multiline
					rows={5}
					inputProps={{
						...inputRef,
					}}
					label={input.label}
					name={input.name}
					type={input.type}
					// defaultValue={input.value}
					sx={{ mx: 1, ...rtl_style, ...sx }}
				/>
			);
		case "select":
			return <FormSelect inputRef={inputRef} input={input} sx={sx} />;
		case "checkbox":
			return (
				<FormInputCheckBox inputRef={inputRef} input={input} sx={sx} />
			);
		case "switch":
			return (
				<FormInputSwitch inputRef={inputRef} input={input} sx={sx} />
			);
		case "range":
			return <FormInputRange inputRef={inputRef} input={input} sx={sx} />;
		case "radio":
			return <FormInputRadio inputRef={inputRef} input={input} sx={sx} />;
		case "hidden":
			return (
				<TextField
					hidden
					inputProps={{
						...inputRef,
					}}
					label={input.label}
					name={input.name}
					type={input.type}
					// defaultValue={input.value}
					sx={{ display: "none" }}
				/>
			);
		default:
			return <></>;
	}
}

export function FormSelect({
	inputRef,
	input = new InputAttributes({}),
	sx = {},
}: {
	inputRef: any;
	input?: InputAttributes;
	sx?: SxProps;
}) {
	const {
		setValue,
		getValues,
		errors,
	}: {
		setValue?: CallableFunction;
		getValues?: CallableFunction;
		errors?: any;
	} = useFormulator();

	return (
		<TextField
			select
			margin="normal"
			inputProps={{
				...inputRef,
			}}
			type={input.type}
			label={input.label}
			name={input.name}
			required={input.required}
			defaultValue={getValues!(input.name)}
			sx={{ mx: 1, ...rtl_style, ...sx, textAlign: "initial" }}
		>
			<MenuItem value={1}>1</MenuItem>
			<MenuItem value={2}>2</MenuItem>
			<MenuItem value={3}>3</MenuItem>
			<MenuItem value={4}>4</MenuItem>
			<MenuItem value={5}>5</MenuItem>
			<MenuItem value={6}>6</MenuItem>
			<MenuItem value={7}>7</MenuItem>
			<MenuItem value={8}>8</MenuItem>
			<MenuItem value={9}>9</MenuItem>
			<MenuItem value={10}>10</MenuItem>
			<MenuItem value={11}>11</MenuItem>
			<MenuItem value={12}>12</MenuItem>
			<MenuItem value={13}>13</MenuItem>
			<MenuItem value={14}>14</MenuItem>
			<MenuItem value={15}>15</MenuItem>
			<MenuItem value={16}>16</MenuItem>
			<MenuItem value={17}>17</MenuItem>
			<MenuItem value={18}>18</MenuItem>
			<MenuItem value={19}>19</MenuItem>
			<MenuItem value={20}>20</MenuItem>
			<MenuItem value={21}>21</MenuItem>
			<MenuItem value={22}>22</MenuItem>
			<MenuItem value={23}>23</MenuItem>
			<MenuItem value={24}>24</MenuItem>
			<MenuItem value={25}>25</MenuItem>
		</TextField>
	);
}
export function FormInputSwitch({
	inputRef,
	input = new InputAttributes({}),
	sx = {},
}: {
	inputRef: any;
	input?: InputAttributes;
	sx?: SxProps;
}) {
	const AntSwitch = styled(Switch)(({ theme }) => ({
		width: 48,
		height: 26,
		padding: 0,
		display: "flex",
		"&:active": {
			"& .MuiSwitch-thumb": {
				width: 25,
			},
			"& .MuiSwitch-switchBase.Mui-checked": {
				transform: "translateX(9px)",
			},
		},
		"& .MuiSwitch-switchBase": {
			padding: 2,
			"&.Mui-checked": {
				transform: "translateX(22px)",
				color: "#fff",
				"& + .MuiSwitch-track": {
					opacity: 1,
					backgroundColor:
						theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
				},
			},
		},
		"& .MuiSwitch-thumb": {
			boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
			width: 22,
			height: 22,
			borderRadius: 12,
			transition: theme.transitions.create(["width"], {
				duration: 200,
			}),
		},
		"& .MuiSwitch-track": {
			borderRadius: 26 / 2,
			opacity: 1,
			backgroundColor:
				theme.palette.mode === "dark"
					? "rgba(255,255,255,.35)"
					: "rgba(0,0,0,.25)",
			boxSizing: "border-box",
		},
	}));

	return (
		<FormControl sx={{ m: 1, ...sx }}>
			<FormControlLabel
				control={
					<AntSwitch
						inputProps={{
							...inputRef,
						}}
					/>
				}
				label={input.label}
			/>
			<FormHelperText>{input.description}</FormHelperText>
		</FormControl>
	);
}

export function FormInputRadio({
	inputRef,
	input = new InputAttributes({}),
	sx = {},
}: {
	inputRef: any;
	input?: InputAttributes;
	sx?: SxProps;
}) {
	return (
		<FormControl required={input.required} sx={{ m: 1, ...sx }}>
			<FormLabel>{input.label}</FormLabel>
			<RadioGroup name={input.name}>
				{input.multiPartInput.map((option: any, option_idx: number) => {
					return (
						<FormControlLabel
							// inputRef={inputController}

							key={option_idx}
							value={option.value}
							control={<Radio />}
							label={option.title}
						/>
					);
				})}
			</RadioGroup>
			<FormHelperText>{input.description}</FormHelperText>
		</FormControl>
	);
}

export function FormInputRange({
	inputRef,
	input = new InputAttributes({}),
	sx = {},
}: {
	inputRef: any;
	input?: InputAttributes;
	sx?: SxProps;
}) {
	return (
		<FormControl sx={{ m: 1, ...sx }}>
			<FormLabel>{input.label}</FormLabel>
			<Slider min={0} max={100} />

			<FormHelperText>{input.description}</FormHelperText>
		</FormControl>
	);
}

export function FormInputCheckBox({
	inputRef,
	input = new InputAttributes({}),
	sx = {},
}: {
	inputRef: any;
	input?: InputAttributes;
	sx?: SxProps;
}) {
	return (
		<FormControl sx={{ m: 1, ...sx }}>
			<FormControlLabel
				control={
					<Checkbox
						inputProps={{
							name: input.name,
							...inputRef,
						}}
						required
						// inputProps={{ name: input.name }}
					/>
				}
				label={input.label}
			></FormControlLabel>
			<FormHelperText>{input.description}</FormHelperText>
		</FormControl>
	);
}

export function FormInputText({
	inputRef,
	input = new InputAttributes({}),
	sx = {},
}: {
	inputRef: any;
	input?: InputAttributes;
	sx?: SxProps;
}) {
	const { errors }: { errors?: any } = useFormulator();
	return (
		<TextField
			sx={{
				mx: 1,
				...rtl_style,
				...sx,
			}}
			inputProps={{
				...(input.type === "number"
					? { min: input.min, max: input.max, defaultValue: 1 }
					: {}),
				...input.inputRef,
				// min: 5,
			}}
			onChange={() => input.onChange()}
			placeholder={input.placeholder}
			type={input.type}
			label={input.label}
			required={input.required}
			variant="outlined"
			helperText={errors![input.name] && errors![input.name]["message"]}
			error={errors![input.name]}
			// // defaultValue={value}
			FormHelperTextProps={
				{
					// sx: {
					// 	textAlign: language.textAlign,
					// },
				}
			}
		/>
	);
}

export function FormInputDate({
	inputRef,
	input = new InputAttributes({}),
	sx = {},
}: {
	inputRef: any;
	input?: InputAttributes;
	sx?: SxProps;
}) {
	const {
		setValue,
		getValues,
		errors,
	}: {
		setValue?: CallableFunction;
		getValues?: CallableFunction;
		errors?: any;
	} = useFormulator();

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			{/* <DemoContainer components={["DatePicker"]}> */}
			<DatePicker
				onChange={(value) => {
					console.log(value);
					setValue!(input.name, value);
				}}
				sx={{
					mx: 1,
					...rtl_style,
					...sx,
				}}
				format="DD-MM-YYYY"
				// ref={input.inputRef.ref}

				defaultValue={getValues!(input.name)}
				label={input.label}
				slotProps={{
					textField: {
						inputProps: {
							...input.inputRef,
						},
						required: input.required,
						helperText:
							errors![input.name] &&
							errors![input.name]["message"],
						error: errors![input.name],
					},
				}}
				// required=
				// label="Basic date picker"
			/>
			{/* </DemoContainer> */}
		</LocalizationProvider>

		// <DatePicker
		// 	label="Controlled picker"
		// 	// value={value}
		// 	// onChange={(newValue) => setValue(newValue)}
		// />
		// <TextField
		// 	type="date"
		// sx={{
		// 	mx: 1,
		// 	...rtl_style,
		// 	...sx,
		// }}
		// inputProps={{
		// 	...input.inputRef,
		// 	min: "1997-01-01",
		// 	max: "2030-12-31",
		// 	pattern: "d{4}-d{2}-d{2}",
		// }}
		// 	// pattern="dd/mm/yyyy"
		// 	// inputinputRef={inputRef}
		// 	// // InputProps={{
		// 	// // 	...([
		// 	// // 		"date",
		// 	// // 		"time",
		// 	// // 		"datetime-local",
		// 	// // 		"month",
		// 	// // 		"week",
		// 	// // 		"color",
		// 	// // 		"file",
		// 	// // 	].includes(input.type)
		// 	// // 		? {
		// 	// // 				// startAdornment: <InputAdornment></InputAdornment>,
		// 	// // 		  }
		// 	// // 		: {
		// 	// // 				endAdornment: (
		// 	// // 					<InputAdornment position="end">
		// 	// // 						<Stack
		// 	// // 							direction="row"
		// 	// // 							divider={
		// 	// // 								<Divider
		// 	// // 									orientation="vertical"
		// 	// // 									flexItem
		// 	// // 								/>
		// 	// // 							}
		// 	// // 						>
		// 	// // 							{/* {endIcons.map((icon, idx) => {
		// 	// // 								return <Box key={idx}>{icon}</Box>;
		// 	// // 							})} */}
		// 	// // 						</Stack>
		// 	// // 					</InputAdornment>
		// 	// // 				),
		// 	// // 		  }),
		// 	// // }}
		// 	// // inputProps={attributes}
		// 	// margin="normal"
		// 	// placeholder={input.placeholder}
		// 	// type={input.type}
		// 	label={input.label}
		// 	required={input.required}
		// 	variant="outlined"
		// 	helperText={input.description}
		// 	// // defaultValue={value}
		// 	FormHelperTextProps={
		// 		{
		// 			// sx: {
		// 			// 	textAlign: language.textAlign,
		// 			// },
		// 		}
		// 	}
		// />
	);
}

export function FormMobileNumberInput({
	inputRef,
	input = new InputAttributes({}),
	sx = {},
}: {
	inputRef: any;
	input?: InputAttributes;
	sx?: SxProps;
}) {
	const {
		setValue,
		getValues,
		errors,
	}: {
		setValue?: CallableFunction;
		getValues?: CallableFunction;
		errors?: any;
	} = useFormulator();

	return (
		<MuiPhoneNumber
			helperText={errors![input.name] && errors![input.name]["message"]}
			error={errors![input.name]}
			required
			disableAreaCodes={true}
			excludeCountries={["il"]}
			sx={{ mx: 1, ...rtl_style, ...sx }}
			inputProps={{
				...inputRef,
				minLength: 13,
				// maxLength: 13,
			}}
			FormHelperTextProps={{
				sx: {
					direction: "rtl",
				},
			}}
			onChange={() => {}}
			dir="ltr"
			label={input.label}
			variant="outlined"
			defaultCountry="jo"
		/>
	);
}
