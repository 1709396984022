import {
	ChangeEventHandler,
	Dispatch,
	ReactElement,
	useContext,
	useEffect,
	useState,
	FC,
} from "react";
import { useParams } from "react-router-dom";
import { makeRequest } from "../../../utility/request";
import FormulatorContext from "./FormulatorContext";
import Formulator from "../classes/Formulator";
import InputAttributes from "../classes/InputAttribures";
import FormulatorSection from "../classes/FormulatorSection";
import { useForm as useReactHookForm } from "react-hook-form";
import FormulatorInfoSection from "../classes/FormulatorInfoSection";
import { Box } from "@mui/material";
// import { register } from "react-hook-form";

export function useFormulatorContext(schema: Formulator) {
	const [form, setForm] = useState<Formulator>(schema);
	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		setError,
		clearErrors,
		formState: { errors },
	} = useReactHookForm();

	// useEffect(() => {
	// 	makeRequest({
	// 		endpoint: `/api/form/get`,
	// 		data: {
	// 			id: form_id,
	// 		},

	// 		requestMessage: {
	// 			pending: "Loading",
	// 			error: "Something went wrong! please contact us!",
	// 		},
	// 		onResolve: (response: any) => {
	// 			setForm(new Form({ ...response.data[0] }));
	// 		},
	// 	});
	// }, [form_id]);

	function initiate_ref(
		formulator_schema: Array<Array<Array<any> | JSX.Element>>
	) {
		setForm(
			new Formulator([
				...formulator_schema.map((section: any) => {
					// 	?
					return new FormulatorSection(
						section.map((input_rows: any, index: number) => {
							return Array.isArray(input_rows)
								? input_rows.map((input: any) => {
										return new InputAttributes({
											...input,
											inputRef: register(
												`${input.name}`,
												{
													required: true,
												}
											),
										});
								  })
								: input_rows;
						})
					);
					// : new FormulatorInfoSection(section);
				}),
			])
		);
	}

	return {
		FormContext: FormulatorContext,
		form: form,
		handleSubmit: handleSubmit,
		register: register,
		setForm: initiate_ref,
		setValue: setValue,
		getValues: getValues,
		setError: setError,
		errors: errors,
		clearErrors: clearErrors,
	};
}

export function useFormulator() {
	return useContext(FormulatorContext.Context);
}
