import { Box, Typography } from "@mui/material";
import { Attendee, useAttendee } from "../../hooks/contexts/UseAttendeeContext";
import { useEffect } from "react";
import { makeRequest } from "../../utility/request";
import { Event, useEvent } from "../../hooks/contexts/UseEventContext";
import { PopupSinger } from "../PopupSinger";

export function WelcomeStep() {
	const { attendee }: { attendee?: Attendee } = useAttendee();
	const { event }: { event?: Event } = useEvent();

	// useEffect(() => {
	// 	// // console.log(event)
	// 	// // console.log(event?.event_date.getDay())
	// }, [event]);

	return event ? (
		<Box sx={{ mb: { xs: 45, md: 0 } }}>
			<Typography
				variant="h4"
				sx={{
					whiteSpace: "pre-wrap",
					fontWeight: "bold",
					color: "#091a86",
				}}
			>
				مرحبا
			</Typography>
			<Typography
				variant="h3"
				sx={{
					whiteSpace: "pre-wrap",
					fontWeight: "bold",
					color: "#f68a26",
				}}
			>
				{attendee?.first_name}
			</Typography>

			<Typography
				variant="h4"
				sx={{
					whiteSpace: "pre-wrap",
					fontWeight: "bold",
					color: "#091a86",
				}}
			>
				في حفل وتر الشرق
			</Typography>

			<Box
				sx={{
					// fill: "",
					m: "auto",
					display: "flex",
					minHeight: "150px",
					width: "100%",
					maxWidth: "500px",
					bgcolor: "#091a86",
					WebkitMask: `url(${event?.svg_path}) no-repeat center`,
					mask: `url(${event?.svg_path}) no-repeat center`,
				}}
			></Box>

			<Typography
				variant="h4"
				sx={{
					whiteSpace: "pre-wrap",
					fontWeight: "bold",
					color: "#091a86",
				}}
			>
				{"يوم " + event?.getDay()}
			</Typography>
			<Typography
				variant="h4"
				sx={{
					whiteSpace: "pre-wrap",
					fontWeight: "bold",
					color: "#f68a26",
				}}
			>
				{event?.getDate()}
			</Typography>

			<PopupSinger
				imageSrc="/images/umkalthom.png"
				text={`يا حبيبي تعالى`}
				initialState={true}
			/>
		</Box>
	) : (
		<></>
	);
}
