import { Box, Typography } from "@mui/material";
import { useFormulator } from "./useForm/UseFormulatorContext";

export function WelcomeByNameSection() {
	const {
		setValue,
		getValues,
	}: { setValue?: CallableFunction; getValues?: CallableFunction } =
		useFormulator();

	return (
		<Typography sx={{ m: 2, textAlign: "start" }} variant="h5">
			اهلا وسهلا
			<Typography
				variant="inherit"
				sx={{
					whiteSpace: "pre-wrap",
					fontWeight: "bold",
					color: "#f68a26",
					display: "inline",
				}}
			>
				{" " + getValues!("first_name")}
			</Typography>
			<br></br> كيف نتواصل معك؟
		</Typography>
	);
}

// <Box>
// 	<Typography
// 		variant="h4"
// 		sx={{
// 			whiteSpace: "pre-wrap",
// 			fontWeight: "bold",
// 			color: "#091a86",
// 		}}
// 	>
// 		أهلا وسهلا
// 	</Typography>
// 	<Typography
// 		variant="h3"
// 		sx={{
// 			whiteSpace: "pre-wrap",
// 			fontWeight: "bold",
// 			color: "#f68a26",
// 		}}
// 	>
// 		{getValues!("first_name")}{" "}
// 	</Typography>
// 	{/* <Typography>أهلا وسهلا</Typography>

//     <Typography>{getValues!("first_name")}</Typography> */}
// </Box>
