import { Box, Button, Container, Paper, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { makeRequest } from "../../utility/request";
import { useEffect, useState } from "react";
import { relative } from "path";
import { ContentCopy, Refresh } from "@mui/icons-material";
import { toast } from "react-toastify";
import { set } from "react-hook-form";

export function EventAnalysis() {
	const [analysis, setAnalysis] = useState([]);

	const [reload, setReload] = useState(0);
	useEffect(() => {
		makeRequest({
			endpoint: "/api/event/get_analysis",
			onResolve: (response: any) => {
				console.log(analysis);

				// sum up all values
				let sum = {
					Class: "Total",
					"Number of people": 0,
					"Number of requested seats": 0,
					"Number of Reserved seats": 0,
					"seat count in theater": 0,
					"Remaining Free Seats": 0,
					"Current Revinue": 0,
					"Possible Revinue": 0,
				};
				response.data.forEach((element: any) => {
					console.log(element)
					sum["Number of people"] += element["Number of people"]??0;
					sum["Number of requested seats"] +=
						element["Number of requested seats"]??0;
					sum["Number of Reserved seats"] +=
						element["Number of Reserved seats"]??0;
					sum["seat count in theater"] +=
						element["seat count in theater"]??0;
					sum["Remaining Free Seats"] +=
						element["Remaining Free Seats"]??0;
					sum["Current Revinue"] +=
					element["Current Revinue"]?element["Current Revinue"].split(" JOD")[0] * 1:0;
					sum["Possible Revinue"] +=
					element["Possible Revinue"]?element["Possible Revinue"].split(" JOD")[0] * 1:0;
				});

				sum["Current Revinue"] = (sum["Current Revinue"] +
					" JOD") as any;
				sum["Possible Revinue"] = (sum["Possible Revinue"] +
					" JOD") as any;
					console.log(sum)
				setAnalysis([...(response.data as any), sum] as any);
			},
			requestMessage: {
				pending: "Loading Analysis..",
				error: "Something went wrong!, please contact us!",
			},
		});
	}, [reload]);

	useEffect(() => {
		setTimeout(() => {
			setReload(reload + 1);
		}, 30000);
	}, [reload]);

	return (
		<Container maxWidth="xl">
			<h1>Event Analysis</h1>
			<Paper sx={{ position: "relative" }}>
				<Button
					onClick={() => {
						setReload(reload + 1);
					}}
					startIcon={<Refresh />}
				>
					On demand refresh
				</Button>

				<Typography>
					This page refreshes automatically every 30 seconds
				</Typography>
				<DataGrid
					getRowId={(row: any) => row.Class}
					columns={[
						{ field: "Class" },
						{ field: "Number of people", width: 150 },
						{ field: "Number of requested seats", width: 200 },
						{ field: "Number of Reserved seats", width: 200 },
						{ field: "seat count in theater", width: 200 },
						{ field: "Remaining Free Seats", width: 200 },
						{ field: "Current Revinue", width: 150 },
						{ field: "Possible Revinue", width: 150 },
					]}
					rows={[...analysis]}
				></DataGrid>
			</Paper>
		</Container>
	);
}
