import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormulator } from "./useForm/UseFormulatorContext";

export function ChooseSeatTypeToggleButton(props: any) {
	const {
		setValue,
		getValues,
	}: { setValue?: CallableFunction; getValues?: CallableFunction } =
		useFormulator();
	const [alignment, setAlignment] = useState(getValues!("ticket_type"));

	const handleChange = (
		event: React.MouseEvent<HTMLElement>,
		newAlignment: string
	) => {
		// console.log(newAlignment);
		document
			.querySelector(".theater")
			?.querySelectorAll(`.seat`)
			.forEach((elm) => elm.classList.remove("chosen"));
		if (newAlignment) {
			document
				.querySelector(".theater")
				?.querySelectorAll(
					`.seat[data-uuid = "${event.currentTarget.dataset.uuid}"]`
				)
				.forEach((elm) => elm.classList.add("chosen"));
			setAlignment(newAlignment);
		}
	};

	useEffect(() => {
		setValue!("ticket_type", alignment);

		document
			.querySelector(".theater")
			?.querySelectorAll(`.seat[data-uuid = "${alignment}"]`)
			.forEach((elm) => elm.classList.add("chosen"));
	}, [alignment]);

	// useEffect()
	return (
		<ToggleButtonGroup
			color="primary"
			value={alignment}
			exclusive
			onChange={handleChange}
			aria-label="Platform"
			sx={{
				width: "100%",
				justifyContent: "center",
				"& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
					borderLeft: "1px solid rgba(0, 0, 0, 0.12)",

					margin: "5px",
					padding: "20px",
					"border-radius": "18px !important",
				},
			}}
		>
			<ToggleButton
				sx={{ display: "none" }}
				value="hidden"
			></ToggleButton>
			<ToggleButton
				value="1"
				// onClick={handleTooltipOpen}
				data-UUID={1}
				className={"seat"}
				onMouseOver={(elm: any) => {
					// handleTooltipOpen();
					document
						.querySelector(".theater")
						?.querySelectorAll(
							`.seat[data-uuid = "${elm.target.dataset.uuid}"]`
						)
						.forEach((elm) => elm.classList.add("selected"));
				}}
				onMouseLeave={(elm: any) => {
					// handleTooltipClose();
					// if (alignment !== "1") {
					document
						.querySelector(".theater")
						?.querySelectorAll(
							`.seat[data-uuid = "${elm.target.dataset.uuid}"]`
						)
						.forEach((elm) => elm.classList.remove("selected"));
					// }
				}}
			>
				Class A <br></br> فئة أ
			</ToggleButton>
			<ToggleButton
				value="2"
				data-UUID={2}
				className={"seat"}
				onMouseOver={(elm: any) => {
					// handleTooltipOpen();
					document
						.querySelector(".theater")
						?.querySelectorAll(
							`.seat[data-uuid = "${elm.target.dataset.uuid}"]`
						)
						.forEach((elm) => elm.classList.add("selected"));
				}}
				onMouseLeave={(elm: any) => {
					// handleTooltipClose();
					// if (alignment !== "2") {
					document
						.querySelector(".theater")
						?.querySelectorAll(
							`.seat[data-uuid = "${elm.target.dataset.uuid}"]`
						)
						.forEach((elm) => elm.classList.remove("selected"));
					// }
				}}
			>
				Class B <br></br> فئة ب
			</ToggleButton>

			<ToggleButton
				value="3"
				data-UUID={3}
				className={"seat"}
				onMouseOver={(elm: any) => {
					// handleTooltipOpen();
					document
						.querySelector(".theater")
						?.querySelectorAll(
							`.seat[data-uuid = "${elm.target.dataset.uuid}"]`
						)
						.forEach((elm) => elm.classList.add("selected"));
				}}
				onMouseLeave={(elm: any) => {
					// handleTooltipClose();
					// if (alignment !== "3") {
					document
						.querySelector(".theater")
						?.querySelectorAll(
							`.seat[data-uuid = "${elm.target.dataset.uuid}"]`
						)
						.forEach((elm) => elm.classList.remove("selected"));
					// }
				}}
			>
				Class C <br></br> فئة ج
			</ToggleButton>
			<ToggleButton
				value="4"
				data-UUID={4}
				className={"seat"}
				onMouseOver={(elm: any) => {
					// handleTooltipOpen();
					document
						.querySelector(".theater")
						?.querySelectorAll(
							`.seat[data-uuid = "${elm.target.dataset.uuid}"]`
						)
						.forEach((elm) => elm.classList.add("selected"));
				}}
				onMouseLeave={(elm: any) => {
					// handleTooltipClose();
					// if (alignment !== "3") {
					document
						.querySelector(".theater")
						?.querySelectorAll(
							`.seat[data-uuid = "${elm.target.dataset.uuid}"]`
						)
						.forEach((elm) => elm.classList.remove("selected"));
					// }
				}}
			>
				Class D <br></br> فئة د
			</ToggleButton>
			<ToggleButton
				sx={{ display: "none" }}
				value="hidden"
			></ToggleButton>
		</ToggleButtonGroup>
	);
}
