import { Box, Container } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import HorizontalNonLinearStepper from "../components/stepper/stepper";
import { useAttendeeContext } from "../hooks/contexts/UseAttendeeContext";
import { PageNotFound } from "./page-not-found";
export function Home() {
	const { AttendeeContext, attendee, setAttendee } = useAttendeeContext();

	return (
		<Box>
			<Container
				// dir="rtl"
				id="ticket-reservation-container"
				maxWidth="xl"
				sx={{ p: "0 !important" }}
			>
				<AttendeeContext.Context.Provider
					value={{ attendee: attendee, setAttendee: setAttendee }}
				>
					{attendee == null /* waiting fetch */ ? (
						<></>
					) : attendee.id == -1 ? (
						<PageNotFound />
					) : (
						<HorizontalNonLinearStepper />
					)}
				</AttendeeContext.Context.Provider>
			</Container>
		</Box>
	);
}
