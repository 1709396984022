import { Route, Routes, useNavigate } from "react-router-dom";
import { AdminMain } from "../pages/admin/admin-main";
import {
	Admin,
	useAdmin,
	useAdminContext,
} from "../hooks/contexts/useAdminContext";
import { LoginPage } from "../pages/admin/login";
import {
	ManageAttendeeTicket,
	ManageAttendees,
} from "../pages/admin/manage-attendees";
import { TicketPage } from "../pages/ticket";
import { useEffect } from "react";
import { SeatMap } from "../pages/admin/seats-map";
import { ManageTickets } from "../pages/admin/manage-tickets";
import { ResetPassowrd } from "../pages/admin/reset_password";
import { ManageEvents } from "../pages/admin/manage-events";
import { EventAnalysis } from "../pages/admin/event-analysis";

export default function AdminRouter() {
	// const { AdminContext, admin, adminUtility } = useAdminContext();

	const navigate = useNavigate();

	const {
		adminUtility,
		admin,
	}: {
		adminUtility?: {
			login: CallableFunction;
			logout: CallableFunction;
			isLoggedIn: CallableFunction;
			loadAdmin: CallableFunction;
		};
		admin?: Admin;
	} = useAdmin();

	useEffect(() => {
		if (!adminUtility?.isLoggedIn()) {
			// console.log("not logged in");
			navigate("/admin/login");
		}
	}, []);

	return (
		<>
			{adminUtility!.isLoggedIn() ? (
				<Routes>
					<Route path="/main" element={<AdminMain />} />
					<Route path="/scan" element={<TicketPage />} />
					<Route
						path={"/admin/tickets/:attendee_id"}
						element={<TicketPage />}
					/>
					<Route
						path={"/manage/attendees"}
						element={<ManageAttendees />}
					/>
					<Route
						path={"/manage/tickets/:attendee_id"}
						element={<ManageAttendeeTicket />}
					/>
					<Route
						path={"/manage/tickets"}
						element={<ManageTickets />}
					/>
					<Route path={"/manage/events"} element={<ManageEvents />} />
					<Route
						path={"/manage/events_analysis"}
						element={<EventAnalysis />}
					/>

					<Route path={"/map"} element={<SeatMap />} />
				</Routes>
			) : (
				<></>
			)}
			<Routes>
				<Route path="/login" element={<LoginPage />} />
			</Routes>
		</>
	);
}
