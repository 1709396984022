import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
	Attendee,
	useAttendee,
} from "../../../hooks/contexts/UseAttendeeContext";
import { useSeats } from "../../../hooks/contexts/useSeatsContext";
import { makeRequest } from "../../../utility/request";
import {
	FormTheaterStandingSeat,
	Theater,
	TheaterOfficial,
	TheaterRow,
	TheaterRowGroup,
	TheaterSeat,
	TicketReservationStepSeat,
} from "../../theater/seat";
import { Row, Seat, SeatGroup, SeatType, TheaterClass } from "../stepper";
import { Area } from "../../theater/Area";
import { AreaType } from "../../theater/AreaType";
import { PopupSinger } from "../../PopupSinger";

export function ChooseSeatStep() {
	const { attendee }: { attendee?: Attendee } = useAttendee();
	const {
		selectedSeats,
		setSelectedSeats,
		reload,
	}: {
		selectedSeats?: Array<number>;
		setSelectedSeats?: CallableFunction;
		reload?: number;
	} = useSeats();

	const [theater, setTheater] = useState();

	useEffect(() => {
		makeRequest({
			endpoint: `/api/seats/get`,
			data: {
				event_id: attendee?.event_id,
			},
			onResolve: (response: any) => {
				setTheater(response.data);
			},
			requestMessage: {
				pending: "Loading Theater..",
				error: "Something went wrong!, please contact us!",
			},
		});
	}, [reload]);

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		// // console.log("Footer Rendered " + reload);
	// 		setReload(reload + 1);
	// 	}, 30000);
	// }, [reload]);

	const theater_id = (theater as any)?.theater_id;
	const adminView = false;

	let textseat = "";
	let count = attendee!.number_of_tickets - selectedSeats!.length;
	if (count === 0) {
		textseat = " يرجى تاكيد الحجز";
	} else if (count === 1) {
		textseat = "يمكنك اختيار مقعد";
	} else if (count === 2) {
		textseat = "يمكنك اختيار مقعدين";
	} else {
		textseat = " يمكنك اختيار " + count + " مقاعد";
	}

	return (
		<>
			<Box
				dir="rtl"
				sx={{
					display: "flex",
					flexDirection: "column",
					m: "0",
					p: 0,
					width: "100%",
				}}
			>
				<Typography
					variant="h4"
					sx={{
						whiteSpace: "pre-wrap",
						fontWeight: "bold",
						color: "#091a86",
					}}
				>
					{textseat}
				</Typography>
				<Typography
					variant="h4"
					sx={{
						whiteSpace: "pre-wrap",
						fontWeight: "bold",
						color: "#f68a26",
					}}
				>
					{textseat == " يرجى تاكيد الحجز"
						? ""
						: attendee?.ticket_type === SeatType.CLASS_A
						? "Class A | فئة أ"
						: attendee?.ticket_type === SeatType.CLASS_B
						? "Class B | فئة ب"
						: attendee?.ticket_type === SeatType.CLASS_C
						? "Class C | فئة ج"
						: "Class D | فئة د"}
				</Typography>

				{/* <Theater
					theater={
						new TheaterClass({
							rows: seats.map((row: any) => {
								return new Row({
									seat_row: row.seat_row,
									seatGroups: row.seat_groups.map(
										(seatGroup: any) => {
											return new SeatGroup({
												seats: seatGroup.seats.map(
													(seat: any) => {
														return new Seat({
															id: seat.id,
															number: seat.seat_column,
															status: seat.taken,
															type: seat.type,
															theater_id:
																seat.theater_id,
														});
													}
												),
											});
										}
									),
								});
							}),
						})
					}
				/> */}
				<TheaterOfficial
					margin={40}
					container_id={"ticket-reservation-container"}
					theater_id={theater_id}
					theater_css={(theater as any)?.theater_css}
				>
					{theater &&
						(theater as any)?.areas.map((area: any) => (
							<Area
								key={area.area_name}
								area_type={area.area_type}
								area_css={area.area_css}
								area_name={area.area_name}
							>
								{area.area_type == AreaType.STANDING ? (
									<FormTheaterStandingSeat />
								) : area.area_type == AreaType.STAGE ? (
									<Box sx={{ color: "white" }}>
										المسرح
										<br></br>
										Stage
									</Box>
								) : (
									// ) : (
									<>
										{area.rows.map((row: any) => (
											<TheaterRow
												area_type={area.area_type}
												row_id={row.row_name}
											>
												{row.groups.map(
													(
														group: any,
														index: number
													) => (
														<TheaterRowGroup
															area_type={
																area.area_type
															}
															group_number={index}
															row_id={
																row.row_name
															}
														>
															{group?.seats?.map(
																(seat: any) => (
																	<TicketReservationStepSeat
																		seat={
																			seat
																		}
																	/>
																)
															)}
														</TheaterRowGroup>
													)
												)}
											</TheaterRow>
										))}
									</>
								)}
							</Area>
						))}
				</TheaterOfficial>
			</Box>
			{selectedSeats?.length !== attendee?.number_of_tickets ? (
				<></>
			) : (
				<PopupSinger
					imageSrc="/images/umkalthom.png"
					text={`خليني جنبك خليني`}
					initialState={true}
				/>
			)}
		</>
	);
}
