import { Box, Typography } from "@mui/material";

export function PageNotFound() {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				height: "85vh",
				direction: "rtl",
			}}
		>
			<Typography
				variant="h1"
				sx={{
					whiteSpace: "pre-wrap",
					// fontWeight: "bold",
					color: "#091a86",
				}}
			>
				404
			</Typography>
			<Typography
				variant="h4"
				sx={{
					whiteSpace: "pre-wrap",
					// fontWeight: "bold",
					color: "#091a86",
				}}
			>
				سواح وماشي في البلاد سواح؟
			</Typography>
			<Typography
				variant="h6"
				sx={{
					whiteSpace: "pre-wrap",
					// fontWeight: "bold",
					color: "#091a86",
				}}
			>
				هاي الصفحة مش موجودة او لسا ما جهزت<br></br>تعال بعدين😜
			</Typography>
		</Box>
	);
}
