import Button from "@mui/material/Button";
import { useFormulator } from "./useForm/UseFormulatorContext";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";

export function NextButton({
	title,
	handleNext,
	checkList,
}: {
	title: string;
	handleNext: CallableFunction;
	checkList?: Array<string>;
}) {
	const {
		setError,
		clearErrors,
		getValues,
	}: {
		getValues?: CallableFunction;
		setError?: CallableFunction;
		clearErrors?: CallableFunction;
	} = useFormulator();
	return (
		<Button
			sx={{ mt: 5 }}
			onClick={() => {
				let count = 0;
				clearErrors!(checkList);
				checkList?.map((name) => {
					if (!getValues!(name)) {
						setError!(name, {
							type: "manual",
							message:
								count === 0
									? "لا تنسى تعبي هون 👆🏻"
									: count === 1
									? "وهون كمان 👆🏻"
									: "وهون 👆🏻",
						});
						count++;
					}

					if (
						name === "phone_number" &&
						getValues!(name).length <= 12
					) {
						setError!(name, {
							type: "manual",
							message:
								count === 0
									? "لا تنسى تعبي هون 👆🏻"
									: count === 1
									? "وهون كمان 👆🏻"
									: "وهون 👆🏻",
						});
						count++;
					}
				});

				if (count === 0) {
					handleNext(true);
				}
			}}
			variant="contained"
		>
			<ArrowRight /> {title}
		</Button>
	);
}

export function BackButton({
	title,
	handleBack,
}: {
	title: string;
	handleBack: CallableFunction;
}) {
	return (
		<Button
			sx={{ mt: 5 }}
			onClick={() => {
				handleBack(true);
			}}
			variant="outlined"
		>
			{title} <ArrowLeft />
		</Button>
	);
}
