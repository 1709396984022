import { Box } from "@mui/material";

export function FormRow(props: any) {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				width: "100%",
				my: 2,
				...props?.sx,
			}}
		>
			{props.children}
		</Box>
	);
}
