import { useState, useEffect } from "react";

function getWindowDimensions(elementId: string) {
	const { width, height } = document
		?.getElementById(elementId)
		?.getBoundingClientRect() ?? { width: 1, height: 1 };
	return {
		width,
		height,
	};
}

export default function useWindowSize(elementId: string = "root") {
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions(elementId)
	);

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions(elementId));
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowDimensions;
}
