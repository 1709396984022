import { Box, Button, Container } from "@mui/material";
import {
	Admin,
	AdminRole,
	useAdmin,
} from "../../hooks/contexts/useAdminContext";
import {
	Analytics,
	ConfirmationNumber,
	Explore,
	Living,
	ManageAccounts,
	QrCode,
	SelectAllSharp,
	Stadium,
	Theaters,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export function AdminMain() {
	const {
		adminUtility,
		admin,
	}: {
		adminUtility?: {
			login: CallableFunction;
			logout: CallableFunction;
			isLoggedIn: CallableFunction;
			loadAdmin: CallableFunction;
		};
		admin?: Admin;
	} = useAdmin();

	const navigate = useNavigate();
	// adminUtility?.isLoggedIn();
	// // console.log(admin);

	useEffect(() => {
		adminUtility?.loadAdmin();
	}, []);

	// useEffect(() => {
	// 	// // console.log(
	// 	// 	admin,
	// 	// 	"888888888888888",
	// 	// 	admin?.role,
	// 	// 	AdminRole.SUPER_USER
	// 	// );
	// }, [admin]);
	return (
		<Container>
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				{Number(admin?.role) <= Number(AdminRole.SUPER_USER) ? (
					<>
						<Button
							onClick={() => {
								navigate("/admin/manage/events_analysis");
							}}
							sx={{
								m: 1,
								p: "20px 0 ",
								flexDirection: "column",
								justifyContent: "center",
							}}
							variant="contained"
						>
							<Analytics />
							وضع الحفلة<br></br> Event Analysis
							<br></br>
						</Button>
						<Button
							onClick={() => {
								navigate("/admin/manage/events");
							}}
							sx={{
								m: 1,
								p: "20px 0 ",
								flexDirection: "column",
								justifyContent: "center",
							}}
							variant="contained"
						>
							<Stadium />
							ادارة الحفلات <br></br> Manage Events
							<br></br>
						</Button>
						<Button
							onClick={() => {
								navigate("/admin/manage/attendees");
							}}
							sx={{
								m: 1,
								p: "20px 0 ",
								flexDirection: "column",
								justifyContent: "center",
							}}
							variant="contained"
						>
							<ManageAccounts />
							ادارة الزوار <br></br> Manage Attendees
						</Button>
					</>
				) : (
					<></>
				)}
				<Button
					onClick={() => {
						navigate("/admin/manage/tickets");
					}}
					sx={{
						m: 1,
						p: "20px 0 ",
						flexDirection: "column",
						justifyContent: "center",
					}}
					variant="contained"
				>
					<ConfirmationNumber />
					ادارة التذاكر <br></br> Manage Tickets
				</Button>
				<Button
					onClick={() => {
						navigate("/admin/scan");
					}}
					sx={{
						m: 1,
						p: "20px 0 ",
						flexDirection: "column",
						justifyContent: "center",
					}}
					variant="contained"
				>
					<QrCode />
					نظام الدخول<br></br> Check In System
				</Button>
				<Button
					onClick={() => {
						navigate("/admin/map");
					}}
					sx={{
						m: 1,
						p: "20px 0 ",
						flexDirection: "column",
						justifyContent: "center",
					}}
					variant="contained"
				>
					<Explore />
					خريطة المقاعد<br></br>Seats Map
				</Button>
			</Box>
		</Container>
	);
}
