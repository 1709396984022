import { Box, Button, Grow } from "@mui/material";
import { useState } from "react";

export function PopupSinger({
	imageSrc,
	text,
	initialState,
}: {
	imageSrc: string;
	text: string;
	initialState: boolean;
}) {
	const [open, setOpen] = useState<boolean>(initialState);

	return (
		<Grow in={false} style={{ transformOrigin: "0 100% 0" }}>
			{/* <Box
				onClick={() => {
					// console.log("اخ");
					setOpen(false);
				}}
				sx={{
					width: "50%",
					display: "flex",
					aspectRatio: "1/1",
					maxWidth: "300px",
					position: "fixed",
					bottom: 40,
					zIndex: 100,
				}}
			>
				<Box
					component={"img"}
					src="/images/fairuz.png"
					sx={{
						objectFit: "contain",
						width: "fit-content",
						height: "145%",
						position: "absolute",
						left: -100,
						bottom: "-125px",
					}}
				/>

				<Box
					component={"img"}
					src="/images/chatbubble.svg"
					sx={{
						position: "absolute",
						left: "70%",
						bottom: "70%",
						width: "50%",
					}}
				/>

				<Box
					sx={{
						position: "absolute",
						left: "65%",
						bottom: "90%",
						color: "white",
						minWidth: "100px",
						width: "50%",
						padding: "20px",
						borderRadius: "18px",
						background: "#f88d28",
						whiteSpace: "pre-line",
					}}
				>
					{text}
				</Box>
			</Box> */}

			<Box
				onClick={() => {
					console.log("اخ");
					setOpen(false);
				}}
				sx={{
					zIndex: 100,
					width: "30vw",
					height: "auto",
					aspectRatio: "1/1",
					maxWidth: "300px",
					// bgcolor: "green",
					position: "fixed",
					display: "flex",
					justifyContent: "center",
					alignItems: "flex-end",
					bottom: 80,
				}}
			>
				<Box
					component={"img"}
					sx={{ width: "100%", objectFit: "contain" }}
					src={imageSrc}
				></Box>
				<Box
					component={"img"}
					src="/images/chatbubble.svg"
					sx={{
						position: "absolute",
						left: "70%",
						bottom: "70%",
						width: "50%",
					}}
				/>

				<Box
					sx={{
						position: "absolute",
						left: "65%",
						bottom: "90%",
						color: "white",
						minWidth: "100px",
						width: "50%",
						padding: "20px",
						borderRadius: "18px",
						background: "#f88d28",
						whiteSpace: "pre-line",
					}}
				>
					{text}
				</Box>
			</Box>
		</Grow>
	);
}
