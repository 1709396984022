import { ReactElement } from "react";
import FormulatorSection from "./FormulatorSection";
import FormulatorInfoSection from "./FormulatorInfoSection";

export default class Formulator {
	sections: Array<FormulatorSection> = [];

	constructor(sections: Array<FormulatorSection>) {
		this.sections = sections;
	}
}
