import { useContext, useState } from "react";
import AdminContext from "./AdminContext";
import { makeRequest } from "../../utility/request";
import { useNavigate } from "react-router-dom";

export function setCookie(cname: string, value: string) {
	document.cookie = cname + "=" + value + ";path=/";
}

export function deleteCookie(cname: string) {
	document.cookie =
		cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export function getCookie(cname: string) {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length);
		}
	}
	return "";
}

export enum AdminRole {
	SUPER_ADMIN = 1,
	SUPER_USER = 2,
	ADMIN = 3,
}

export class Admin {
	id: string;
	role: AdminRole;
	username: string;
	phone_number: string;

	constructor({
		id,
		role,
		username,
		phone_number,
	}: {
		id: string;
		role: AdminRole;
		username: string;
		phone_number: string;
	}) {
		this.id = id;
		this.username = username;
		this.role = role;
		this.phone_number = phone_number;
	}
}

export function useAdminContext() {
	const [admin, setAdmin] = useState<Admin | null>();
	const navigate = useNavigate();

	function logout() {
		setAdmin(null);

		// This code will be of no use once we are sure all local storage session token are removed
		if (localStorage.getItem("access_token") != null)
			localStorage.removeItem("access_token");
		if (localStorage.getItem("accesstoken") != null)
			localStorage.removeItem("accesstoken");

		makeRequest({
			endpoint: "/api/user/logout",
			onResolve: () => {},
			onCatch: () => {
				// remove is_logged_in cookie
				deleteCookie("is_logged_in");
			},
			requestMessage: {
				pending: "Logging out..",
				error: "Unsuccessful logout!",
			},
		});

		navigate("/admin/login");
	}

	function loadAdmin() {
		if (isLoggedIn() && !admin) {
			makeRequest({
				endpoint: "/api/user/relogin",

				onResolve: (response: any) => {
					login(new Admin({ ...response.data }));
				},
				onCatch: () => {
					logout();
				},

				requestMessage: {
					pending: "Checking you have access..",
					error: "No access granted!",
				},
			});
		}
	}

	function login(admin: Admin) {
		setAdmin(admin);
		navigate("/admin/main");
	}

	function isLoggedIn(): Boolean {
		// This code will be of no use once we are sure all local storage session token are removed
		if (localStorage.getItem("access_token") != null)
			localStorage.removeItem("access_token");
		if (localStorage.getItem("accesstoken") != null)
			localStorage.removeItem("accesstoken");

		// Check if is Logged In from is_logged_in Cookie
		if (getCookie("is_logged_in")) {
			return true;
		}

		return false;
	}

	return {
		AdminContext: AdminContext,
		admin: admin,
		adminUtility: {
			login: login,
			logout: logout,
			isLoggedIn: isLoggedIn,
			loadAdmin: loadAdmin,
		},
	};
}

export function useAdmin() {
	return useContext(AdminContext.Context);
}
