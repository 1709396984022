import { Save } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
	Attendee,
	useAttendee,
} from "../../../hooks/contexts/UseAttendeeContext";
import { Event, useEvent } from "../../../hooks/contexts/UseEventContext";

export function DownloadTicketsButton() {
	const { attendee }: { attendee?: Attendee } = useAttendee();
	const { event }: { event?: Event } = useEvent();

	return (
		<Box
			sx={{
				position: "fixed",
				bottom: "0",
				left: "0",
				width: "100%",
				height: "80px",
				zIndex: 30,
				direction: "rtl",
			}}
		>
			<Button
				variant="contained"
				size="large"
				onClick={async () => {
					var scaleBy = 2;
					var w = 400;
					var h = 800;

					var pdf = new jsPDF("p", "px", [w, h]);

					const render = async (names: Array<string>) => {
						if (names.length >= 1) {
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[0]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
						if (names.length >= 2) {
							pdf.addPage();
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[1]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
						if (names.length >= 3) {
							pdf.addPage();
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[2]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
						if (names.length >= 4) {
							pdf.addPage();
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[3]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
						if (names.length >= 5) {
							pdf.addPage();
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[4]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
						if (names.length >= 6) {
							pdf.addPage();
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[5]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
						if (names.length >= 7) {
							pdf.addPage();
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[6]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
						if (names.length >= 8) {
							pdf.addPage();
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[7]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
						if (names.length >= 9) {
							pdf.addPage();
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[8]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
						if (names.length >= 10) {
							pdf.addPage();
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[9]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
						if (names.length >= 11) {
							pdf.addPage();
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[10]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
						if (names.length >= 12) {
							pdf.addPage();
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[11]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
						if (names.length >= 13) {
							pdf.addPage();
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[12]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
						if (names.length >= 14) {
							pdf.addPage();
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[13]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
						if (names.length >= 15) {
							pdf.addPage();
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[13]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
						if (names.length >= 16) {
							pdf.addPage();
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[13]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
						if (names.length >= 17) {
							pdf.addPage();
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[13]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
						if (names.length >= 18) {
							pdf.addPage();
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[13]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
						if (names.length >= 19) {
							pdf.addPage();
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[13]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
						if (names.length >= 20) {
							pdf.addPage();
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[13]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
						if (names.length >= 21) {
							pdf.addPage();
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[13]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
						if (names.length >= 22) {
							pdf.addPage();
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[13]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
						if (names.length >= 23) {
							pdf.addPage();
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[13]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
						if (names.length >= 24) {
							pdf.addPage();
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[13]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
						if (names.length >= 25) {
							pdf.addPage();
							pdf.addImage(
								await html2canvas(
									document.getElementById(
										names[13]
									) as HTMLElement,
									{ scale: 1 }
								),
								"png",
								0,
								0,
								w,
								h
							);
						}
					};

					let ids: Array<string> = [];
					const elems = document.getElementsByClassName("ticket");

					for (let i = 0; i < elems.length; i++) {
						ids.push(elems[i].id);
					}

					await render(ids);

					pdf.save(
						`وتر الشرق - ${
							event?.name
						} - ${event?.getDate()} - جميع التذاكر.pdf`
					);
				}}
				sx={{ width: "100%", height: "100%", direction: "ltr" }}
				startIcon={<Save />}
			>
				حفظ الجميع
			</Button>
		</Box>
	);
}
