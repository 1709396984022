import { AccountCircle, Login, Password } from "@mui/icons-material";
import {
	Box,
	Button,
	Container,
	InputAdornment,
	TextField,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { makeRequest } from "../../utility/request";
import { useNavigate } from "react-router-dom";
import { Admin, useAdmin } from "../../hooks/contexts/useAdminContext";

export function LoginPage() {
	const [username, setUsername] = useState<string>();
	const [password, setPassword] = useState<string>();

	const {
		adminUtility,
	}: {
		adminUtility?: {
			login: CallableFunction;
			logout: CallableFunction;
			isLoggedIn: CallableFunction;
		};
	} = useAdmin();

	const navigate = useNavigate();

	useEffect(() => {
		if (adminUtility?.isLoggedIn()) {
			navigate("/admin/main");
		}
	}, []);
	return (
		<Container maxWidth="xs">
			<Box sx={{ display: "flex", flexDirection: "column", p: "60px 0" }}>
				<Box sx={{ pb: "30px" }}>
					<Box
						sx={{
							mb: "20px",
							width: "170px",
							fill: "#091a86",
						}}
						component={"svg"}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 341.35 181.21"
					>
						<defs></defs>
						<title>watar logo</title>
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<path
									className="cls-1"
									d="M88.46,123q9.14-13.26,14-35.55t-7.52-35a18.5,18.5,0,0,0-4.75-2.59A4.26,4.26,0,0,0,85.06,51a40.65,40.65,0,0,0-7,11.1q-2.68,6.27-5.55,12Q68.94,80.92,72.7,85c1.55,1.68,4,2.66,7.25,3a28.67,28.67,0,0,0,9.4-.63c1.2,1.67,1.91,4.24,2.15,7.7s.36,6.39.36,8.78a66.81,66.81,0,0,1-14,9.31,65.13,65.13,0,0,1-18.45,6.53q-9.22,1.71-16.47-1.25t-8.15-14.06a50.22,50.22,0,0,1,.8-14.77,86.11,86.11,0,0,0,1.71-13.88q0-3-3.23-2.68A43.59,43.59,0,0,0,20,77.87,58.14,58.14,0,0,0,9.13,85.75Q6.44,92,4.3,98.46T0,111.54a37.77,37.77,0,0,1,25.61-15.4c.95-.12,1.58.42,1.88,1.61a12.87,12.87,0,0,1,.44,2.86,51,51,0,0,1-1.07,10q-3.22,16.83,3,25.25a22.32,22.32,0,0,0,16.66,9.31q10.38.9,22.11-4.92A52.59,52.59,0,0,0,88.46,123Z"
								/>
								<path
									className="cls-1"
									d="M148.48,62.22a67.8,67.8,0,0,1,8.33-12q4.56-5.19,9.4-5.73h.54q4.65,0,8.41,9.31c.48,1.08,1,1.61,1.44,1.61a1.57,1.57,0,0,0,1.25-.71q3.59-4.66,7-9.76a88.93,88.93,0,0,0,6.09-10.66,39.93,39.93,0,0,0-6.26-8.41q-3.94-3.59-7.7-3.58a9.16,9.16,0,0,0-5,1.61A53.39,53.39,0,0,0,160.3,35.54,150.68,150.68,0,0,0,150,50.85q-4.57,7.78-6.9,12.26l-.18.36.18,7.7A90.62,90.62,0,0,0,148.48,62.22Z"
								/>
								<path
									className="cls-1"
									d="M154.22,120.41a1.51,1.51,0,0,0,1.61,1.08q3.39,0,9.49-2.87c1.2-.56,2.28-1.13,3.27-1.7l-.59,3.32-.18.71q25.26-6.08,31.52-9.49,18.27-9.66,23.28-30.08a52.41,52.41,0,0,0,1.61-8.78A75.69,75.69,0,0,0,224.59,62L221.37,2.41,208.12,23.54q1.24,22.56,2.23,36.89t1,27.93a81.31,81.31,0,0,1-15.94,5.28q-5.52,1.2-14.8,2.78a147.12,147.12,0,0,0,2.27-14.87l-.18-.17c0,.12-.06.17-.18.17a1.32,1.32,0,0,0-.54.9q-3,12.36-16.47,14.68a32.07,32.07,0,0,0,1.07-8V87.64c0-.47-.06-.71-.18-.71a4.51,4.51,0,0,0-.53.71,18.26,18.26,0,0,0-2.15,4.21,8.08,8.08,0,0,1-2.51,3.67,42.24,42.24,0,0,1-11.28,5.91,10.22,10.22,0,0,1-3.4.72q-3.22,0-3.23-4.66V95.34c.12-1.19-.6-1.85-2.15-2a9.79,9.79,0,0,0-1.79-.18q-6.27,0-18.44,3.76-14,4.48-16.83,10a51,51,0,0,0-2.78,6.72c-1.2,3.61-2.74,7.1-4.17,10.63a10.83,10.83,0,0,1-.78,1.65,9.9,9.9,0,0,1-1.46,1.76c-6.73,6.82-15.17,12.42-23,17.87a160.67,160.67,0,0,0-20.81,17.16q-1.82,2,0,2.56,2.86.63,15-1t15.08-2.47q9.42-2.26,14.72-6.55c7.45-6,6.08-16.57,7.31-25a12.25,12.25,0,0,1,.91-3.58c.8-1.66,3.65-3.85,5.38-4.48q5.91-2.15,14-4.65a19.76,19.76,0,0,1,5.73-1.26c1.31,0,2,.48,2,1.44v2q0,5.73,5.37,5.73a16.48,16.48,0,0,0,2.33-.18q8.76-1.62,12.71-3.58A12.22,12.22,0,0,1,154.22,120.41Z"
								/>
								<polygon
									className="cls-1"
									points="248.63 76.1 243.43 0 230.18 20.95 235.38 97.06 248.63 76.1"
								/>
								<path
									className="cls-1"
									d="M300,78.51,312.47,56.6q-16.7,2.3-36.4,4.79L263.59,83.12q9.11-1.2,18.2-2.31T300,78.51Z"
								/>
								<path
									className="cls-1"
									d="M274.16,136.24q15.51-3.94,22.48-14.72t8.28-27.34a20.84,20.84,0,0,1-9.07,5.26,88.15,88.15,0,0,1-10.12,2.37q-11.31,2.35-21.29,3.81a42.81,42.81,0,0,0-17.62,6.44,35.08,35.08,0,0,0-11.56,12.67,59.47,59.47,0,0,0-3.56,7.42c-1,2.64-1.61,5.43-2.79,8a6.23,6.23,0,0,1-1.43,2.16,7.57,7.57,0,0,1-4.07,1.42c-1.59.25-3.12.72-4.69,1.07-4,.9-7.92,1.81-11.88,2.69-7.93,1.76-15.89,3.43-23.9,4.78A236,236,0,0,0,144.56,162c-2.46,1.05-3,2.19-1.58,3.41q3.42,2.64,20.51,8T185,179.62q13.94,2.9,23.67.52,11-2.88,18.13-16.29c2.34-4.69,5-9.27,7.45-13.91a15.13,15.13,0,0,1,2.06-3.17c1.75-1.91,4.28-2.7,6.69-3.4a76.06,76.06,0,0,1,7.72-2Q260.24,139.66,274.16,136.24Z"
								/>
								<path
									className="cls-1"
									d="M332.93,78.76a12.27,12.27,0,0,0-4.65-2.69,4.82,4.82,0,0,0-1.8-.36,4.25,4.25,0,0,0-3.22,1.61,41.35,41.35,0,0,0-7,11q-2.68,6.18-5.73,11.91a13.23,13.23,0,0,0-1.61,5.9,7.41,7.41,0,0,0,2,5.2c1.55,1.67,4,2.62,7.34,2.86a17.52,17.52,0,0,0,2.5.18,31.18,31.18,0,0,0,6.81-.72,12.73,12.73,0,0,1,1.43,3.59,8.17,8.17,0,0,1,.18,1.61,4,4,0,0,1-.54,1.43A36.4,36.4,0,0,1,315,130q-15.39,6.27-31.06,12.27a236.51,236.51,0,0,0-29.64,13.69,1.71,1.71,0,0,0-.72,1.44c0,.71.3,1.07.9,1.07q9.84,1.08,20.05,1.43h3.59a93.41,93.41,0,0,0,16.47-1.43q12-2.33,22.83-11.73a73.35,73.35,0,0,0,17.19-21.58,56.69,56.69,0,0,0,6.72-24.88V99.17A28.76,28.76,0,0,0,332.93,78.76Z"
								/>
							</g>
						</g>
					</Box>
					<Typography
						variant="h4"
						sx={{
							whiteSpace: "pre-wrap",
							fontWeight: "bold",
							color: "#091a86",
						}}
					>
						مرحبا ايها الشخص الفخم
					</Typography>
					<Typography
						variant="h3"
						sx={{
							whiteSpace: "pre-wrap",
							fontWeight: "bold",
							color: "#f68a26",
						}}
					>
						{/* {attendee?.first_name} */}
					</Typography>

					<Typography
						variant="h4"
						sx={{
							whiteSpace: "pre-wrap",
							fontWeight: "bold",
							color: "#091a86",
						}}
					>
						سجل دخولك للمتابعة
					</Typography>
				</Box>
				<Box sx={{ m: 1 }}>
					<TextField
						onChange={(value) => {
							setUsername(value.target.value.toString());
						}}
						id="usename"
						label="Username | اسم المستخدم"
						sx={{ width: "100%" }}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<AccountCircle />
								</InputAdornment>
							),
						}}
					/>
				</Box>
				<Box sx={{ m: 1 }}>
					<TextField
						onChange={(value) => {
							setPassword(value.target.value.toString());
						}}
						id="password"
						type="password"
						label="Password | كلمة السر"
						sx={{ width: "100%" }}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Password />
								</InputAdornment>
							),
						}}
					/>
				</Box>
				<Box sx={{ m: 1 }}>
					<Button
						disabled={!username || !password}
						sx={{ width: "100%", bgcolor: "#f68a26" }}
						variant="contained"
						startIcon={<Login />}
						onClick={() => {
							makeRequest({
								endpoint: "/api/user/login",
								data: {
									username: username,
									password: password,
								},

								onResolve: (response: any) => {
									adminUtility?.login(
										new Admin({ ...response.data })
									);
								},

								requestMessage: {
									pending: "Logging in..",
									error: "wrong username or password!",
									success: "هلا بالغالي",
								},
							});
						}}
					>
						Login | تسجيل دخول
					</Button>
				</Box>
				<Box sx={{ m: 1 }}>
					<Button sx={{ width: "100%" }}>
						Forgot Password? | نسيت كلمة السر؟
					</Button>
				</Box>
			</Box>
		</Container>
	);
}
