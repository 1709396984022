import { Box, Button, Container, Paper, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { makeRequest } from "../../utility/request";
import { useEffect, useState } from "react";
import { relative } from "path";
import { ContentCopy } from "@mui/icons-material";
import { toast } from "react-toastify";
import { set } from "react-hook-form";

export function ManageEvents() {
	const [events, setEvents] = useState([]);

	const [reload, setReload] = useState(0);
	useEffect(() => {
		makeRequest({
			endpoint: "/api/event/get_all",
			onResolve: (response: any) => {
				setEvents(response.data);
			},
			requestMessage: {
				pending: "Loading Events..",
				error: "Something went wrong!, please contact us!",
			},
		});
	}, [reload]);

	return (
		<Container>
			<h1>Manage Events</h1>
			<Paper sx={{ position: "relative" }}>
				<DataGrid
					rowHeight={250}
					columns={[
						{
							field: "id",
						},
						{ field: "name", width: 200 },

						{ field: "event_date", width: 200 },
						{ field: "status", width: 200 },
						{
							field: "ticket_link",
							type: "actions",
							headerName: "Link\nرابط الحجز",
							width: 250,
							cellClassName: "actions",
							renderCell: ({ row }: any) => (
								<Box>
									<Button
										variant="contained"
										onClick={() => {
											// setReload(reload + 1);

											makeRequest({
												endpoint: `/api/event/update_state`,
												data: {
													id: row.id,
													status: "LAUNCHED",
												},
												onResolve: (response: any) => {
													setReload(reload + 1);
												},
											});
										}}
									>
										ورجي العد الاتنازلي
										<br></br>
										Set Count Down State
									</Button>
									<br></br>
									<br></br>
									<Button
										variant="contained"
										onClick={() => {
											// setReload(reload + 1);

											makeRequest({
												endpoint: `/api/event/update_state`,
												data: {
													id: row.id,
													status: "REGISTRATION_STARTED",
												},
												onResolve: (response: any) => {
													setReload(reload + 1);
												},
											});
										}}
									>
										افتح فورم التسجيل
										<br></br>
										Open Registration Form
									</Button>
									<br></br>
									<br></br>
									<Button
										variant="contained"
										onClick={() => {
											// setReload(reload + 1);
											console.log(row);
											makeRequest({
												endpoint: `/api/event/update_state`,
												data: {
													id: row.id,
													status: "REGISTRATION_CLOSED",
												},
												onResolve: (response: any) => {
													setReload(reload + 1);
												},
											});
										}}
									>
										اغلق فورم التسجيل
										<br></br>
										Close Registration Form
									</Button>
								</Box>
							),
							// getActions: ({ row }: any) => {
							// 	return [
							// 		// <GridActionsCellItem
							// 		// 	icon={<ContentCopy />}
							// 		// 	label="Edit"
							// 		// 	className="textPrimary"
							// 		// 	onClick={() => {
							// 		// 		navigator.clipboard.writeText(
							// 		// 			`watarelsharq.com/register/${row.name}`
							// 		// 		);
							// 		// 		toast.success("Copied!", {
							// 		// 			position: "top-right",
							// 		// 			autoClose: 2000,
							// 		// 			hideProgressBar: true,
							// 		// 			closeOnClick: true,
							// 		// 			pauseOnHover: true,
							// 		// 			draggable: true,
							// 		// 			progress: undefined,
							// 		// 			theme: "light",
							// 		// 		});
							// 		// 	}}
							// 		// 	color="inherit"
							// 		// />,
							// 		// <Button
							// 		// 	action={() => {
							// 		// 		setReload(reload + 1);
							// 		// 	}}
							// 		// >
							// 		// 	hi
							// 		// </Button>,
							// 	];
							// },
						},
					]}
					rows={[...events]}
				></DataGrid>
			</Paper>
		</Container>
	);
}
