import { Box, createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import MainRouter from "./routers/main-router";
// import { Footer } from "./components/footer/footer";
// import { NavigationBar } from "./components/navigation/navigation-bar";

// import { FursanRoutes } from "./routes/Routes";

const theme = createTheme({
	palette: {
		primary: {
			main: "#091a86",
		},
	},
});

function App() {
	return (
		<ThemeProvider theme={theme}>
			<Box
				className="App"
				sx={{
					maxWidth: "100%",
					position: "relative",
					bgcolor: "#f5f5f5",
					minHeight: "100vh",

					"& *": {
						fontFamily: "'Almarai', sans-serif !important",
					},
				}}
			>
				<Box
					sx={{
						overflowX: "clip",
						maxWidth: "100%",

						position: "relative",
					}}
				>
					<Box
						component="img"
						sx={{
							width: "100%",
							height: "40px",
							background: "#091a86",
							padding: "20px 0",
						}}
						src="/images/watar logo.svg"
					></Box>
					<Box
						component="img"
						sx={{
							height: "50vw",
							objectFit: "contain",
							position: "absolute",
							top: "-25vw",
							right: "-39vw",
						}}
						src="/images/Asset 1.png"
					></Box>

					<Box
						component="img"
						sx={{
							height: "50vw",
							objectFit: "contain",
							position: "absolute",
							top: "-27vw",
							right: "39vw",
						}}
						src="/images/Asset 2_1.png"
					></Box>
				</Box>
				<Router>
					<Routes>
						<Route path="/*" element={<MainRouter />} />
					</Routes>
				</Router>
				<ToastContainer
					position="bottom-center"
					autoClose={5000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="colored"
				/>
			</Box>
		</ThemeProvider>
	);
}

export default App;
