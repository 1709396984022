import { Box, Fade } from "@mui/material";
import Paper from "@mui/material/Paper";
import FormulatorSection from "./classes/FormulatorSection";
import { FormInput } from "./froms";
import { useFormulator } from "./useForm/UseFormulatorContext";
import { FormRow } from "./FormRow";
import { makeRequest } from "../../utility/request";
import { Event, useEvent } from "../../hooks/contexts/UseEventContext";

export function FormSection({
	section,
	handleNext,
}: {
	section: FormulatorSection;
	handleNext: CallableFunction;
}) {
	// const {
	// 	register,
	// 	// handleSubmit,
	// 	// formState: { errors },
	// } = useForm();
	const {
		handleSubmit,
		register,
		setError,
	}: {
		handleSubmit?: CallableFunction;
		register?: CallableFunction;
		setError?: CallableFunction;
	} = useFormulator();
	const { event }: { event?: Event } = useEvent();

	return (
		<Fade in={true} mountOnEnter unmountOnExit timeout={1000}>
			<Paper
				id="registration-container"
				dir="rtl"
				sx={{
					minHeight: "calc(90vh - 80px)",

					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "flex-start",
					px: "20px",
					boxSizing: "border-box",
					zIndex: 99,
					position: "relative",
				}}
			>
				{/* <Typography sx = {{m:1}}>{props.step.label}</Typography> */}
				{/* <Typography sx = {{m:1}} align="left">{props.step.description}</Typography> */}
				<Box
					sx={{ width: "100%" }}
					component={"form"}
					onSubmit={handleSubmit!((data: any) => {
						// // console.log(data, "*****");

						makeRequest({
							endpoint: "/api/attendee/insert",
							data: {
								email: data["email"],

								first_name: data["first_name"],
								last_name: data["last_name"],
								event_id: event!.id,
								phone_number: data["phone_number"],
								number_of_tickets: data["number_of_tickets"],
								ticket_type: Number(data["ticket_type"]),
								note: ` ${data["date_of_birth"].year()}-${
									data["date_of_birth"].month() + 1
								}-${data["date_of_birth"].date()}`,
							},
							requestMessage: {
								pending: "يتم تسجيل الحجز",
								success: "تم تسجيل الحجز",
								error: "حصل خطب ما! يرجى التواصل معنا",
							},

							onResolve: () => {
								handleNext(true);
							},
						});
					})}
				>
					{section.rows.map((row: any, idx: number) => (
						<FormRow key={idx}>
							{Array.isArray(row)
								? row.map((input: any, iidx: number) => {
										return (
											<FormInput
												key={iidx}
												inputRef={input.inputRef}
												sx={{ width: "100%" }}
												input={input}
											/>
										);
								  })
								: row}
						</FormRow>
					))}
					{/* {section instanceof FormulatorInfoSection &&
                section.children} */}
				</Box>
			</Paper>
		</Fade>
	);
}
