import { Box, SxProps, Tooltip } from "@mui/material";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useSeats } from "../../hooks/contexts/useSeatsContext";
import {
	Seat,
	SeatGroup,
	SeatStatus,
	SeatType,
	TheaterClass,
} from "../stepper/stepper";
import { Attendee, useAttendee } from "../../hooks/contexts/UseAttendeeContext";
import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";
import { opacity } from "html2canvas/dist/types/css/property-descriptors/opacity";
import { AreaType } from "./AreaType";
import useWindowSize from "../../hooks/UseWindowSize/useWindowSize";

export function TheaterSeatGroup({ seatsGroup }: { seatsGroup: SeatGroup }) {
	return (
		<Box sx={{ display: "flex", m: "5px", flexDirection: "row-reverse" }}>
			{seatsGroup.seats.map((seat, index) => (
				<TheaterSeat key={index} seat={seat} />
			))}
		</Box>
	);
}

export function Theater({ theater }: { theater: TheaterClass }) {
	const { selectedSeats }: { selectedSeats?: Array<number> } = useSeats();
	const { attendee }: { attendee?: Attendee } = useAttendee();

	const theater_id = theater.rows[0]?.seatGroups[0].seats[0].theater_id;
	return (
		<Box
			sx={{
				".R": { mb: theater_id === 2 ? "unset" : "3vw" },
				".M, .F": { mb: theater_id === 2 ? "2vw" : "unset" },
			}}
		>
			{theater.rows.map((row, index) => (
				<Box
					key={index}
					className={row.row_id}
					sx={{
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					<Box
						sx={{
							display: "flex",
							mr: 1,
							width: 5,
							alignItems: "center",
							color: "#757575",
							fontSize: "min(calc(2.5vw + .05vmin),15px)", // {xs:6,md:"calc(0.7vw + .05vmin)"},
						}}
					>
						{row.row_id}
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							flexDirection: "row-reverse",
							width: "90%",
						}}
					>
						{row.seatGroups.map((group, indexx) => (
							<TheaterSeatGroup key={indexx} seatsGroup={group} />
						))}
					</Box>
					<Box
						sx={{
							display: "flex",
							ml: 1,
							width: 5,
							alignItems: "center",
							color: "#757575",
							fontSize: "min(calc(2.5vw + .05vmin),15px)", // {xs:6,md:"calc(0.7vw + .05vmin)"},
						}}
					>
						{row.row_id}
					</Box>
				</Box>
			))}
			{theater.rows.length ? (
				<Box
					className={
						attendee?.number_of_tickets === selectedSeats?.length
							? "hide"
							: ""
					}
					sx={{
						bgcolor: "#f68a26",
						height: "100px",
						borderRadius: "40% 40% 0 0",
						width: "80%",
						margin: "auto",
						marginTop: "30px",
						marginBottom: "50px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						color: "white",
						fontWeight: "bold",
						opacity: 1,

						transition: "opacity 0.5s, background-color .3s ",
						"&.hide": {
							opacity: 0.1,
						},
					}}
				>
					المسرح
				</Box>
			) : (
				<></>
			)}
		</Box>
	);
}

export function TheaterOfficial(props: any) {
	const theater_id = props.theater_id;
	const theater_css = props.theater_css ? props.theater_css : "{}";
	const margin = props.margin ? props.margin : 40;
	const threaterRef = useRef();

	const { width, height } = useWindowSize(props.container_id);

	const [scale, setScale] = useState(1);

	const [theaterSize, setTheaterSize] = useState({ width: 1, height: 1 });

	const [state, setState] = useState("init");

	useEffect(() => {
		// console.log(state, width);

		if (state == "reset_scale") {
			setScale(1);
			setState("calculate_theater_width");
		}

		if (state == "calculate_theater_width") {
			// const

			// console.log(
			// 	document
			// 		.getElementsByClassName("theater")[0]
			// 		.getBoundingClientRect(),
			// 	"hiiii"
			// );
			setTheaterSize(
				document
					.getElementsByClassName("theater")[0]
					.getBoundingClientRect()
			);
			setState("calculate_scale");
		}

		if (state == "calculate_scale") {
			setScale((width - margin) / theaterSize.width);
			setState("Done");
		}
	}, [state]);

	// useEffect(() => {
	// 	setState("init");
	// }, []);

	useEffect(() => {
		// console.log(
		// 	width,
		// 	theaterSize,
		// 	(width - margin) / theaterSize.width,
		// 	"reset_scale"
		// );
		// console.log(
		// 	document
		// 		.getElementsByClassName("theater")[0]
		// 		.getBoundingClientRect(),
		// 	"hello"
		// );
		setState("reset_scale");
	}, [theater_id]);

	useEffect(() => {
		// console.log(width, theaterSize.width);
		setState("calculate_scale");
	}, [width]);

	// useEffect(() => {
	// 	// console.log(scale);
	// }, [scale]);

	return (
		<Box
			sx={{
				rotate: "180deg",
				// overflow: "hidden",
				// width: "100%",
				opacity: state != "Done" ? 0 : 1,
			}}
		>
			<Box
				ref={threaterRef}
				className="theater"
				dir="ltr"
				sx={{
					/* just so you can see the box */
					transform: `scale(${scale})`,
					height:
						state == "Done"
							? theaterSize.height * scale + 100
							: "100%",
					transformOrigin: "top right",
					width: "fit-content",
					// bgcolor: "gray",
					// TODO Margin should be received from theater DB record/
					".R": { mb: theater_id === 1 ? "3vw" : "unset" },
					".M, .F": { mb: theater_id === 2 ? "2vw" : "unset" },
					// ".L": { mb: theater_id === 3 ? "2vw" : "unset" },
					// overflow: "hidden",

					// "&.theater:hover .seat": {
					// 	opacity: 0.3,
					// 	// background: aqua;
					// },

					position: "relative",
					display: "grid",

					...JSON.parse(theater_css),
				}}
			>
				{props.children}
			</Box>
		</Box>
	);
}

export function TheaterRow(props: any) {
	return (
		<Box
			// key={"index"}
			className={`theater-row ${props.row_id}`}
			sx={{
				display: "flex",
				flexDirection:
					props.area_type == AreaType.DOWN ||
					props.area_type == AreaType.UP
						? "row"
						: "column",

				// justifyContent: "space-between",
				width: "100%",
				gap: "10px",
				alignItems: "center",
			}}
		>
			<Box
				className={`row-label-left-${props.row_id}`}
				sx={{
					display: "flex",
					// mr: 1,
					width: 5,
					alignItems: "center",
					rotate: "180deg",

					color: "#757575",
					fontSize: "min(calc(2.5vw + .05vmin),15px)", // {xs:6,md:"calc(0.7vw + .05vmin)"},
				}}
			>
				{props.row_id}
			</Box>
			<Box
				className={`theater-groups theater-groups-${props.row_id}`}
				sx={{
					display: "flex",
					justifyContent: "space-between",
					flexDirection:
						props.area_type == AreaType.DOWN ||
						props.area_type == AreaType.UP
							? "row-reverse"
							: "column-reverse",
					width: "100%",
					// width: "90%",
					gap: "30px",
				}}
			>
				{/* {row.seatGroups.map((group, indexx) => (
			<TheaterSeatGroup key={indexx} seatsGroup={group} />
		))} */}

				{props.children}
			</Box>
			<Box
				className={`row-label-right-${props.row_id}`}
				sx={{
					display: "flex",
					// ml: 1,
					width: 5,
					alignItems: "center",
					color: "#757575",
					rotate: "180deg",

					fontSize: "min(calc(2.5vw + .05vmin),15px)", // {xs:6,md:"calc(0.7vw + .05vmin)"},
				}}
			>
				{props.row_id}
			</Box>
		</Box>
	);
}

export function TheaterRowGroup(props: any) {
	return (
		<Box
			// key={"index"}
			className={`theater-row-group ${props.row_id}-${props.group_number}`}
			sx={{
				display: "flex",
				m: "5px",

				flexDirection:
					props.area_type == AreaType.DOWN ||
					props.area_type == AreaType.UP
						? "row-reverse"
						: "column-reverse",
			}}
		>
			{props.children}
		</Box>
	);
}

export function TheaterSeat2({ seat }: { seat: any }) {
	const [open, setOpen] = useState(false);

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = () => {
		setOpen(true);
	};

	return (
		<Tooltip
			onClose={handleTooltipClose}
			open={open}
			title={
				<Box>
					<Box>{seat.first_name}</Box>
					<Box>Ticket No {seat.ticket_id}</Box>
				</Box>
			}
			// enterTouchDelay={0}
			// leaveTouchDelay={0}
		>
			<Box
				data-UUID={seat.attendee_id}
				component={"button"}
				onClick={handleTooltipOpen}
				onMouseOver={(elm: any) => {
					handleTooltipOpen();
					document
						.querySelector(".theater")
						?.querySelectorAll(
							`.seat[data-uuid = "${elm.target.dataset.uuid}"]`
						)
						.forEach((elm) => elm.classList.add("selected"));
				}}
				onMouseLeave={(elm: any) => {
					handleTooltipClose();
					document
						.querySelector(".theater")
						?.querySelectorAll(
							`.seat[data-uuid = "${elm.target.dataset.uuid}"]`
						)
						.forEach((elm) => elm.classList.remove("selected"));
				}}
				className={`seat ${"key" + 1}`}
				sx={{
					outline: "none",
					border: "none",
					p: "5px",
					// display: "flex",
					width: "30px", //"calc( 500p / 70 ) !important",
					// fontSize: "min(calc(1.5vw + .05vmin),15px)",
					// fontSize: "3%",
					// maxWidth: 30,
					// minWidth:"calc(65vw / 27)",
					aspectRatio: "1/1 !important",
					// flexGrow: 0,
					// flexShrink: 0,
					// p: 0,
					borderRadius: "50% 50% 0 0",

					bgcolor: seat.taken
						? seat.has_checked_in
							? "blue"
							: "green"
						: "Orange",

					// opacity: 1,
					transition: "opacity 0.5s, background-color .3s ",
					alignItems: "center",
					justifyContent: "center",
					color: "white",

					// margin: "0 0.5px",
					whiteSpace: "nowrap",

					"&.selected": {
						bgcolor: "black",
						opacity: "1 !important",
					},

					visibility:
						seat.type === SeatType.HIDDEN ? "hidden" : "visible",
					display:
						seat.theater_id === 2 && seat.type === SeatType.HIDDEN
							? "none"
							: "flex",
				}}
			>
				{seat.seat_column}
			</Box>
		</Tooltip>
	);
}

export function FormTheaterSeat({ seat }: { seat: any }) {
	const [open, setOpen] = useState(false);

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = () => {
		setOpen(true);
	};

	return (
		<Tooltip
			onClose={handleTooltipClose}
			open={open}
			title={
				<Box>
					<Box>
						{seat.type === SeatType.CLASS_A
							? "Class A | فئة أ"
							: seat.type === SeatType.CLASS_B
							? "Class B | فئة ب"
							: seat.type === SeatType.CLASS_C
							? "Class C | فئة ج"
							: "Class D | فئة د"}
					</Box>
				</Box>
			}
			// enterTouchDelay={0}
			// leaveTouchDelay={0}
		>
			<Box
				data-UUID={seat.type}
				// component={"button"}
				onClick={handleTooltipOpen}
				onMouseOver={(elm: any) => {
					handleTooltipOpen();
					document
						.querySelector(".theater")
						?.querySelectorAll(
							`.seat[data-uuid = "${elm.target.dataset.uuid}"]`
						)
						.forEach((elm) => elm.classList.add("selected"));
				}}
				onMouseLeave={(elm: any) => {
					handleTooltipClose();
					document
						.querySelector(".theater")
						?.querySelectorAll(
							`.seat[data-uuid = "${elm.target.dataset.uuid}"]`
						)
						.forEach((elm) => elm.classList.remove("selected"));
				}}
				className={`seat ${"key" + 1}`}
				sx={{
					outline: "none",
					border: "none",
					p: "5px",
					// display: "flex",
					width: "30px", //"calc( 500p / 70 ) !important",
					// fontSize: "min(calc(1.5vw + .05vmin),15px)",
					// fontSize: "3%",
					// maxWidth: 30,
					// minWidth:"calc(65vw / 27)",
					aspectRatio: "1/1 !important",
					// flexGrow: 0,
					// flexShrink: 0,
					// p: 0,
					borderRadius: "50% 50% 0 0",

					bgcolor: "#9b9b9b",
					// seat.taken
					// 	? seat.has_checked_in
					// 		? "blue"
					// 		: "green"
					// :

					// opacity: 0.5,
					rotate: "180deg",
					transition: "opacity 0.1s, background-color .3s ",
					alignItems: "center",
					justifyContent: "center",
					color: "white",

					margin: "0 0.5px",
					whiteSpace: "nowrap",

					"&.selected": {
						opacity: "1 !important",
						bgcolor: "#f68a26",
					},

					"&.chosen": {
						opacity: "1 !important",
						bgcolor: "green",
					},

					visibility:
						seat.type === SeatType.HIDDEN ? "hidden" : "visible",
					display:
						seat.theater_id === 2 && seat.type === SeatType.HIDDEN
							? "none"
							: "flex",
				}}
			>
				{seat.seat_column}
			</Box>
		</Tooltip>
	);
}

export function FormTheaterStandingSeat() {
	const [open, setOpen] = useState(false);

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = () => {
		setOpen(true);
	};

	return (
		<Tooltip
			onClose={handleTooltipClose}
			open={open}
			title={
				<Box>
					<Box>{"Class D | فئة د"}</Box>
				</Box>
			}
			// enterTouchDelay={0}
			// leaveTouchDelay={0}
		>
			<Box
				data-UUID={4}
				// component={"button"}
				onClick={handleTooltipOpen}
				onMouseOver={(elm: any) => {
					handleTooltipOpen();
					document
						.querySelector(".theater")
						?.querySelectorAll(
							`.seat[data-uuid = "${elm.target.dataset.uuid}"]`
						)
						.forEach((elm) => elm.classList.add("selected"));
				}}
				onMouseLeave={(elm: any) => {
					handleTooltipClose();
					document
						.querySelector(".theater")
						?.querySelectorAll(
							`.seat[data-uuid = "${elm.target.dataset.uuid}"]`
						)
						.forEach((elm) => elm.classList.remove("selected"));
				}}
				className={`seat ${"key" + 1}`}
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					m: 2,

					width: "100%",
					// bgcolor: "orange",
					height: "180px",
					outline: "none",
					border: "none",
					p: "5px",
					// display: "flex",
					// width: "30px", //"calc( 500p / 70 ) !important",
					// fontSize: "min(calc(1.5vw + .05vmin),15px)",
					// fontSize: "3%",
					// maxWidth: 30,
					// minWidth:"calc(65vw / 27)",
					aspectRatio: "1/1 !important",
					// flexGrow: 0,
					// flexShrink: 0,
					// p: 0,
					// borderRadius: "50% 50% 0 0",

					bgcolor: "#9b9b9b",
					// seat.taken
					// 	? seat.has_checked_in
					// 		? "blue"
					// 		: "green"
					// :
					rotate: "180deg",
					opacity: 0.5,
					transition: "opacity 0.5s, background-color .3s ",
					// alignItems: "center",
					// justifyContent: "center",
					color: "white",

					margin: "0 0.5px",
					whiteSpace: "nowrap",

					"&.selected": {
						opacity: "1 !important",
						bgcolor: "#f68a26",
					},

					"&.chosen": {
						opacity: "1 !important",
						bgcolor: "green",
					},
				}}
			>
				منطقة الجلوس حسب الاسبقية
				<br></br>
				(مدرج وليست مقاعد)
				<br></br>First Come First Set Area
			
			</Box>
		</Tooltip>
	);
}

export function TicketReservationStepSeat({ seat }: { seat: any }) {
	const colors = {
		normal: {
			selected: "#01a526",
			free: "#ffbe82",
			hover: "#f68a26",
			reserved: "#5e5e5e",
		},
		vip: {
			selected: "#01a526",
			free: "#8500ff",
			hover: "#f1a526",
			reserved: "f02ab26",
		},
	};
	const {
		selectedSeats,
		setSelectedSeats,
	}: { selectedSeats?: Array<number>; setSelectedSeats?: CallableFunction } =
		useSeats();
	const { attendee }: { attendee?: Attendee } = useAttendee();

	const [selected, setSelected] = useState(false);

	useEffect(() => {
		if (selectedSeats?.indexOf(seat.id) != -1) {
			setSelected(true);
		} else {
			setSelected(false);
		}
	}, [selectedSeats]);

	const myseattype = attendee?.ticket_type;
	// // console.log("steaaats", attendee, myseattype);

	return (
		<Box
			component={"button"}
			disabled={
				seat.type != myseattype ||
				seat.taken ||
				(attendee?.number_of_tickets === selectedSeats?.length &&
					!selected)
			}
			onClick={() => {
				// // console.log(selectedSeats);
				if (selectedSeats?.includes(seat.id)) {
					setSelectedSeats!(
						selectedSeats?.filter((e) => e !== seat.id)
					);
				} else {
					setSelectedSeats!([...selectedSeats!, seat.id]);
				}
			}}
			className="seat"
			sx={{
				// outline: "none",
				// border: "none",
				// p: "5px",
				// // display: "flex",
				// width: "30px", //"calc( 500p / 70 ) !important",
				// // fontSize: "min(calc(1.5vw + .05vmin),15px)",
				// // fontSize: "3%",
				// // maxWidth: 30,
				// // minWidth:"calc(65vw / 27)",
				aspectRatio: "1/1 !important",
				// // flexGrow: 0,
				// // flexShrink: 0,
				// // p: 0,
				// borderRadius: "50% 50% 0 0",width: "30px"

				// bgcolor: "#9b9b9b",
				// // seat.taken
				// // 	? seat.has_checked_in
				// // 		? "blue"
				// // 		: "green"
				// // :

				// opacity: 0.5,
				// transition: "opacity 0.5s, background-color .3s ",
				// alignItems: "center",
				// justifyContent: "center",
				// color: "white",

				// margin: "0 0.5px",
				// whiteSpace: "nowrap",

				// "&.selected": {
				// 	opacity: "1 !important",
				// 	bgcolor: "#f68a26",
				// },

				// "&.chosen": {
				// 	opacity: "1 !important",
				// 	bgcolor: "green",
				// },

				// visibility:
				// 	seat.type === SeatType.HIDDEN ? "hidden" : "visible",
				// display:
				// 	seat.theater_id === 2 && seat.type === SeatType.HIDDEN
				// 		? "none"
				// 		: "flex",

				// display: "flex",
				justifyContent: "center",
				alignItems: "center",
				m: 2,

				// width: "100%",
				// bgcolor: "orange",
				// height: "150px",
				outline: "none",
				border: "none",
				p: "5px",
				width: "30px",

				// outline: "none",
				// border: "none",
				// display: "flex",
				// width: "calc(50vw / 27)",
				// width: "calc(65vw / 27)", //{xs:"11px !important",md:"calc(50vw / 27)"},
				maxWidth: 30,
				// aspectRatio: "1/1",
				flexGrow: 0,
				flexShrink: 0,
				// p: 0,
				borderRadius: "50% 50% 0 0",

				bgcolor: selected
					? colors.normal.selected
					: seat.taken == ""
					? colors.normal.free
					: colors.normal.reserved,

				// opacity: 1,
				transition: "background-color .3s ",
				// alignItems: "center",
				// justifyContent: "center",
				color: "white",
				// fontSize: "min(calc(1.5vw + .05vmin),15px)", // {xs:6,md:"calc(0.7vw + .05vmin)"},

				margin: "0 0.5px",
				whiteSpace: "nowrap",

				"&:not([disabled]):hover ": {
					cursor: "pointer",
					bgcolor: { xs: "none", md: colors.normal.hover },
				},

				"&[disabled]": {
					opacity: 0.1,
				},

				visibility:
					seat.type === SeatType.HIDDEN ? "hidden" : "visible",
				display:
					seat.theater_id === 2 && seat.type === SeatType.HIDDEN
						? "none"
						: "flex",
			}}
		>
			{seat.seat_column}
		</Box>
	);
}

export function TheaterSeat({ seat }: { seat: Seat }) {
	const colors = {
		normal: {
			selected: "#01a526",
			free: "#ffbe82",
			hover: "#f68a26",
			reserved: "#5e5e5e",
		},
		vip: {
			selected: "#01a526",
			free: "#8500ff",
			hover: "#f1a526",
			reserved: "f02ab26",
		},
	};
	const {
		selectedSeats,
		setSelectedSeats,
	}: { selectedSeats?: Array<number>; setSelectedSeats?: CallableFunction } =
		useSeats();
	const { attendee }: { attendee?: Attendee } = useAttendee();

	const [selected, setSelected] = useState(false);

	useEffect(() => {
		if (selectedSeats?.indexOf(seat.id) != -1) {
			setSelected(true);
		} else {
			setSelected(false);
		}
	}, [selectedSeats]);

	const myseattype = attendee?.ticket_type;

	// // console.log(seat.type)
	return (
		<Box
			component={"button"}
			disabled={
				seat.type != myseattype ||
				seat.status === SeatStatus.RESERVED ||
				(attendee?.number_of_tickets === selectedSeats?.length &&
					!selected)
			}
			onClick={() => {
				// // console.log(selectedSeats);
				if (selectedSeats?.includes(seat.id)) {
					setSelectedSeats!(
						selectedSeats?.filter((e) => e !== seat.id)
					);
				} else {
					setSelectedSeats!([...selectedSeats!, seat.id]);
				}
			}}
			className="seat"
			sx={{
				// display: "flex",
				justifyContent: "center",
				alignItems: "center",
				m: 2,

				width: "100%",
				// bgcolor: "orange",
				height: "150px",
				outline: "none",
				border: "none",
				p: "5px",

				// outline: "none",
				// border: "none",
				// display: "flex",
				// width: "calc(50vw / 27)",
				// width: "calc(65vw / 27)", //{xs:"11px !important",md:"calc(50vw / 27)"},
				maxWidth: 30,
				aspectRatio: "1/1",
				flexGrow: 0,
				flexShrink: 0,
				// p: 0,
				borderRadius: "50% 50% 0 0",

				bgcolor: selected
					? colors.normal.selected
					: seat.status === SeatStatus.FREE
					? colors.normal.free
					: colors.normal.reserved,

				opacity: 1,
				transition: "opacity 0.5s, background-color .3s ",
				// alignItems: "center",
				// justifyContent: "center",
				color: "white",
				fontSize: "min(calc(1.5vw + .05vmin),15px)", // {xs:6,md:"calc(0.7vw + .05vmin)"},

				margin: "0 0.5px",
				whiteSpace: "nowrap",

				"&:not([disabled]):hover ": {
					cursor: "pointer",
					bgcolor: { xs: "none", md: colors.normal.hover },
				},

				"&[disabled]": {
					opacity: 0.1,
				},

				visibility:
					seat.type === SeatType.HIDDEN ? "hidden" : "visible",
				display:
					seat.theater_id === 2 && seat.type === SeatType.HIDDEN
						? "none"
						: "flex",
			}}
		>
			{seat.number}
		</Box>
	);
}
