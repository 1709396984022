import {
	Box,
	Button,
	CircularProgress,
	Container,
	Drawer,
	TextField,
	Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Admin, useAdmin } from "../hooks/contexts/useAdminContext";
import { useEffect, useRef, useState } from "react";
import QrReader from "react-qr-reader";
import { makeRequest } from "../utility/request";
import { Search } from "@mui/icons-material";
// import QrReader from "react-qr-reader";
// import { QrReader } from "react-qr-reader";

export function TicketCheckInButton({
	ticket,
	selectedSeats,
	setSelectedSeats,
}: any) {
	const [selected, setSelected] = useState(false);

	useEffect(() => {
		if (selectedSeats?.indexOf(ticket.id) != -1) {
			setSelected(true);
		} else {
			setSelected(false);
		}
	}, [selectedSeats]);

	return (
		<Button
			disabled={ticket.has_checked_in}
			// component={Button}
			color={selected ? "success" : "info"}
			variant="contained"
			sx={{ width: "100px", m: 1, flexGrow: 0 }}
			onClick={() => {
				// console.log(selectedSeats);
				if (selectedSeats?.includes(ticket.id)) {
					setSelectedSeats!(
						selectedSeats?.filter((e: any) => e !== ticket.id)
					);
				} else {
					setSelectedSeats!([...selectedSeats!, ticket.id]);
				}
			}}
		>
			{ticket.seat}
		</Button>
	);
}

export function TicketPage() {
	const {
		adminUtility,
		admin,
	}: {
		adminUtility?: {
			login: CallableFunction;
			logout: CallableFunction;
			isLoggedIn: CallableFunction;
			loadAdmin: CallableFunction;
		};
		admin?: Admin;
	} = useAdmin();

	// const navigate = useNavigate();

	const { ticket_id } = useParams();
	const [id, setId] = useState(ticket_id ? Number(ticket_id) : -1);

	const boxId = useRef(id);
	let handleScan = (data: any) => {
		if (data) {
			// console.log(data);

			let temp = data.toString().split("/");

			setId(temp[temp.length - 1]);

			setOpenDrawer(true);
		}
	};

	// useEffect(() => {
	// 	boxId.current = id;
	// }, [id]);

	let handleError = (err: any) => {};

	const [openCamera, setOpenCamera] = useState<boolean>(false);
	const [openDrawer, setOpenDrawer] = useState<boolean>(false);

	return (
		<Container maxWidth="sm" sx={{ p: { xs: 0 } }}>
			{adminUtility?.isLoggedIn() ? (
				<>
					<Box
						sx={{
							width: "100%",
							aspectRatio: "1/1",
							bgcolor: "gray",
						}}
					>
						{openCamera ? (
							<QrReader
								delay={300}
								onError={handleError}
								onScan={handleScan}
								style={{ width: "100%" }}
								facingMode="environment"
							/>
						) : (
							<></>
						)}
					</Box>

					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<TextField
							sx={{
								width: "100%",
							}}
							defaultValue={boxId.current}
							type="number"
							onChange={(value: any) => {
								boxId.current = value.target.value;
							}}
						/>

						<Box>
							<Button
								startIcon={<Search />}
								sx={{
									p: 2,
									"& span": {
										m: 0,
									},
								}}
								variant="contained"
								onClick={() => {
									// console.log(value)
									setId(boxId.current);
									setOpenDrawer(true);
								}}
							></Button>
						</Box>
					</Box>

					<Box>
						<Button
							variant="contained"
							onClick={() => {
								setOpenCamera(!openCamera);
							}}
						>
							{openCamera ? "Close" : "Open"} Camera
						</Button>
					</Box>

					<CheckInDrawer
						ticket_id={id}
						setOpenDrawer={setOpenDrawer}
						openDrawer={openDrawer}
					/>
				</>
			) : (
				<>{ticket_id}</>
			)}
		</Container>
	);
}

class CheckInObj {
	id: string;
	attendee_id: number;
	name: string;
	ticket_type: number;
	payment_status: number;
	tickets: any;

	constructor({
		id,
		name,
		attendee_id,

		payment_status,
		ticket_type,
		tickets,
	}: {
		id: string;
		name: string;
		ticket_type: number;
		attendee_id: number;

		payment_status: number;
		tickets: any;
	}) {
		this.id = id;
		this.attendee_id = attendee_id;
		this.name = name;
		this.payment_status = payment_status;
		this.ticket_type = tickets[0]["ticket_type"];

		this.tickets = tickets;
	}
}

function CheckInDrawer({
	setOpenDrawer,
	openDrawer,

	ticket_id,
}: {
	setOpenDrawer: CallableFunction;
	openDrawer: boolean;

	ticket_id: number;
}) {
	const [requestStatus, setRequestStatus] = useState<string>("pending");
	const [obj, setObj] = useState<CheckInObj>();

	const [selectedSeats, setSelectedSeats] = useState<Array<number>>([]);
	const [reload, setReload] = useState(0);

	useEffect(() => {
		if (ticket_id != -1) {
			setOpenDrawer(true);
		}
	}, []);

	useEffect(() => {
		setSelectedSeats([]);
		// console.log(ticket_id);
		if (ticket_id == -1) return;
		makeRequest({
			endpoint: "/api/ticket/get_ticket_info",
			data: {
				id: ticket_id,
			},
			onResolve: (response: any) => {
				// setRequestStatus("resolved");
				setObj(new CheckInObj({ ...response?.data }));
				// console.log(response.data);
			},
		});
	}, [ticket_id, reload]);

	useEffect(() => {
		// console.log(selectedSeats);
	}, [selectedSeats]);

	useEffect(() => {
		// setSelectedSeats([ticket_id])

		// console.log(obj);

		if (obj?.tickets?.length != 0 && requestStatus != "success") {
			setRequestStatus("retirved");
		}

		if (!obj || !obj?.tickets) {
			setRequestStatus("404");
		}

		if (obj?.payment_status == 0) {
			// request payment
		}
	}, [obj]);

	return (
		<Drawer
			keepMounted={true}
			open={openDrawer}
			onClose={() => {
				setOpenDrawer(false);
			}}
			PaperProps={{
				sx: {
					overflow: "unset",
				},
			}}
			anchor="bottom"
			sx={{ height: "400px" }}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					position: "relative",
					alignItems: "center",
					boxSizing: "border-box",
					width: "100%",
					p: "20px",
					pb: "40px",
					pt: "60px",
				}}
			>
				{requestStatus == "retirved" ? (
					<IdMessage id={ticket_id} />
				) : (
					<></>
				)}

				{requestStatus == "success" ? <SuccessfulMessage /> : <></>}

				{requestStatus == "404" ? (
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<FailurMessage />
						<Box>لم يتم العثور على التذكرة</Box>
						<Box>يرجى فتح الرابط المرسل على الواتساب</Box>
					</Box>
				) : (
					<></>
				)}
				{requestStatus == "pending" ? <WaitingMessage /> : <></>}

				{requestStatus == "pending" ? (
					<></>
				) : (
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{obj?.payment_status == 0 ? (
							<>
								<Typography
									variant="h5"
									sx={{
										whiteSpace: "pre-wrap",
										color: "#f68a26",
									}}
								>
									Not Paid | غير مدفوع
								</Typography>
								<Typography
									variant="h5"
									sx={{
										whiteSpace: "pre-wrap",
										color: "#f68a26",
									}}
								>
									{"المبلغ المطلوب: " +
										(obj?.ticket_type == 1
											? 15
											: obj?.ticket_type == 2
											? 12
											: obj?.ticket_type == 3
											? 10
											: 10) *
											obj?.tickets.length +
										" دينار  "}
								</Typography>

								<Typography
									variant="h5"
									sx={{
										whiteSpace: "pre-wrap",
										color: "#f68a26",
									}}
								>
									يرجى التوجه لبوث الدفع
								</Typography>
							</>
						) : (
							<></>
						)}
						<Typography
							variant="h4"
							sx={{
								whiteSpace: "pre-wrap",
								color: "#f68a26",
							}}
						>
							{obj?.name}
						</Typography>
{obj?.payment_status != 0?
<>
						<Box
						sx={{
							// height: "20/0px",
							display: "flex",
							flexDirection: "row",
							flexWrap: "wrap",
							justifyContent: "center",
								alignItems: "center",
								pb: "20px",
							}}
							>
							{ obj?.tickets?.map((ticket: any) => (
								<TicketCheckInButton
								selectedSeats={selectedSeats}
								setSelectedSeats={setSelectedSeats}
								ticket={ticket}
								/>
							))}
						</Box>

						<Button
						disabled={selectedSeats.length == 0 }
						sx={{ width: "100%" }}
						variant="contained"
						onClick={() => {
								// console.log(selectedSeats);
								setRequestStatus("pending");
								makeRequest({
									endpoint: "/api/ticket/checkin",
									data: {
										ids: selectedSeats,
									},
									onResolve: (response: any) => {
										setRequestStatus("success");
										setReload((prev) => prev + 1);
										// setOpenDrawer(false);
									},
								});
							}}
							>
							تاكيد الحجز
						</Button>
								</>:null

						}
						{/* {obj?.payment_status == 0 ? (
							<Button
								sx={{ width: "100%", mt: 1 }}
								variant="contained"
								onClick={() => {
									// console.log(selectedSeats);
									setRequestStatus("pending");
									makeRequest({
										endpoint:
											"/api/attendee/update_payment_status",
										data: {
											id: obj.attendee_id,
											payment_status: 1,
										},
										onResolve: (response: any) => {
											setRequestStatus("success");
											setReload((prev) => prev + 1);
											// setOpenDrawer(false);
										},
									});
								}}
							>
								تاكيد الدفع
							</Button>
						) : (
							<></>
						)} */}
					</Box>
				)}
			</Box>
		</Drawer>
	);
}
function SuccessfulMessage() {
	return (
		<Box
			sx={{
				height: "100px",
				width: "100px",
				borderRadius: "100%",
				bgcolor: "green",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				position: "absolute",
				top: "-50px",
			}}
		>
			<Box
				className="swal2-icon swal2-success swal2-animate-success-icon"
				sx={{
					display: "flex",
					m: 0,
					"& span": {
						bgcolor: "green !important",
					},
				}}
			>
				<Box
					sx={{ bgcolor: "white" }}
					className="swal2-success-ring"
				></Box>
				<span className="swal2-success-line-tip"></span>
				<span className="swal2-success-line-long"></span>
			</Box>
			{/* 1831 */}
		</Box>
	);
}

function FailurMessage() {
	return (
		<Box
			sx={{
				// marginTop: "-50px",
				height: "100px",
				width: "100px",
				borderRadius: "100%",
				bgcolor: "red",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				position: "absolute",
				top: "-50px",
				// top,
			}}
		>
			<Box
				className="swal2-icon swal2-error swal2-animate-error-icon"
				sx={{
					display: "flex",
					bgcolor: "white",
					m: 0,
					"& span ": {
						bgcolor: "red !important",
					},
				}}
			>
				<span className="swal2-x-mark-line-left"></span>
				<span className="swal2-x-mark-line-right"></span>
				{/* <Box sx={{ bgcolor: "white" }} className="swal2-x-mark"></Box> */}
			</Box>
		</Box>
	);
}

function WaitingMessage() {
	return (
		<Box
			sx={{
				// marginTop: "-50px",
				height: "100px",
				width: "100px",
				borderRadius: "100%",
				bgcolor: "#d1d1d1",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				position: "absolute",
				top: "-50px",
				// top,
			}}
		>
			<CircularProgress sx={{ color: "white" }} />
		</Box>
	);
}

function IdMessage({ id }: { id: number }) {
	return (
		<Box
			sx={{
				// marginTop: "-50px",
				height: "100px",
				width: "100px",
				borderRadius: "100%",
				bgcolor: "white",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				position: "absolute",
				top: "-50px",
				// top,
			}}
		>
			{id}
			{/* <CircularProgress sx={{ color: "white" }} /> */}
		</Box>
	);
}
// }
