import { Box, Button } from "@mui/material";
import { useState } from "react";
import {
	Attendee,
	useAttendee,
} from "../../../hooks/contexts/UseAttendeeContext";
import { useSeats } from "../../../hooks/contexts/useSeatsContext";
import { useStepper } from "../../../hooks/contexts/useStepperContext ";
import { makeRequest } from "../../../utility/request";
import { PopupSinger } from "../../PopupSinger";

export function ChooseSeatStepperButton() {
	const { handleNext }: { handleNext?: CallableFunction } = useStepper();
	const { attendee }: { attendee?: Attendee } = useAttendee();
	const {
		selectedSeats,
		setReload,
		setSelectedSeats,
	}: {
		selectedSeats?: Array<number>;
		setReload?: CallableFunction;
		setSelectedSeats?: CallableFunction;
	} = useSeats();
	const [open, setOpen] = useState<boolean>(false);

	const reseve_tickets = () => {
		makeRequest({
			endpoint: `/api/ticket/insert`,
			data: {
				attendee_id: attendee?.id,
				event_id: attendee?.event_id,
				seat_ids: selectedSeats,
			},
			onResolve: (response: any) => {
				// // console.log(response.data);
				handleNext!();
			},
			onCatch: (err: any) => {
				setOpen(false);

				// console.log(err.response.status);
				setOpen(true);
				setReload!((prev: number) => prev + 1);
				setSelectedSeats!([]);
			},
			requestMessage: {
				pending: "بناكدلك الحجز",
				error: "حدا سبقك للكرسي",
			},
		});
	};

	return (
		<>
			<Box
				sx={{
					position: "fixed",
					bottom: "0",
					left: "0",
					width: "100%",
					height: "80px",
					zIndex: 30,
					direction: "rtl",
				}}
			>
				<Button
					variant="contained"
					size="large"
					disabled={
						selectedSeats?.length !== attendee?.number_of_tickets
					}
					onClick={() => reseve_tickets()}
					sx={{
						width: "100%",
						height: "100%",

						"&.Mui-disabled": {
							bgcolor: "grey !important",

							color: "white",
						},
					}}
				>
					{selectedSeats?.length === attendee?.number_of_tickets
						? "تاكيد الحجز"
						: `${selectedSeats?.length} من ${attendee?.number_of_tickets}`}
				</Button>
			</Box>
			{open ? (
				<Box sx={{ zIndex: 23 }}>
					<PopupSinger
						imageSrc="/images/umkalthom.png"
						text={`فـــــات الميعــــاد
							
						حد سبقك عالكرسي
						اختار كرسي تانـــــــــي`}
						initialState={true}
					/>
				</Box>
			) : (
				<></>
			)}
		</>
	);
}
