import { Route, Routes } from "react-router-dom";
import { Home } from "../pages/home";
import { TicketPage } from "../pages/ticket";
import AdminRouter from "./admin-router";
import { PageNotFound } from "../pages/page-not-found";
import { ResetPassowrd } from "../pages/admin/reset_password";
import { useAdminContext } from "../hooks/contexts/useAdminContext";
import { Registration } from "../pages/registration";

export default function MainRouter() {
	// if (!user) return <NotFound status="NOTLOGGEDIN" />;
	const { AdminContext, admin, adminUtility } = useAdminContext();

	return (
		<AdminContext.Context.Provider
			value={{
				admin: admin,
				adminUtility: adminUtility,
			}}
		>
			<Routes>
				<Route
					path="/admin/reset_password/:reset_token"
					element={<ResetPassowrd />}
				/>

				<Route path={"admin/*"} element={<AdminRouter />} />

				<Route
					key={"route.name"}
					path={"/:attendee_id"}
					element={<Home />}
				/>
				<Route
					key={"ticket"}
					path={"/ticket/:ticket_id"}
					element={<TicketPage />}
				/>

				<Route
					key={"registration"}
					path={"/register/:event_name"}
					element={<Registration />}
				/>
				<Route
					key={"notfound"}
					path={"/*"}
					element={<PageNotFound />}
				/>
			</Routes>
		</AdminContext.Context.Provider>
	);
}
