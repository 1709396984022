import {
	Box,
	Button,
	Container,
	MenuItem,
	TextField,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSeats } from "../../hooks/contexts/useSeatsContext";
import { makeRequest } from "../../utility/request";
import {
	Theater,
	TheaterRow,
	TheaterRowGroup,
	TheaterSeat2,
	FormTheaterSeat,
	TheaterOfficial,
	FormTheaterStandingSeat,
} from "../../components/theater/seat";
import {
	Row,
	Seat,
	SeatGroup,
	TheaterClass,
} from "../../components/stepper/stepper";
import { Event } from "../../hooks/contexts/UseEventContext";
import { ChooseSeatTypeToggleButton } from "../../components/Formulator/ChooseSeatTypeToggleButton";
import { Area } from "../../components/theater/Area";
import { AreaType } from "../../components/theater/AreaType";

export function SeatMap() {
	const [eventId, setEventId] = useState<number>(0);
	// useEffect(() => {
	// 	// // console.log(eventId);
	// }, [eventId]);

	const [eventList, setEventList] = useState<Array<Event>>([]);

	useEffect(() => {
		makeRequest({
			endpoint: `/api/event/get_all`,
			data: {},

			requestMessage: {
				pending: "Loading",
				error: "Something went wrong! please contact us!",
			},
			onResolve: (response: any) => {
				setEventList(
					response.data.map(
						(eventObj: any) => new Event({ ...eventObj })
					)
				);
			},
		});
	}, []);

	return (
		<Container
			id="map-container"
			maxWidth="lg"
			sx={{ p: "20px" }}
			dir="rtl"
		>
			<TextField
				defaultValue={0}
				select
				sx={{ width: "100%" }}
				onChange={(value) => {
					setEventId(Number(value.target.value));
				}}
			>
				<MenuItem value={0}>اختر الحفلة</MenuItem>
				{eventList.map((event) => (
					<MenuItem value={event?.id}>
						{"حفلة " +
							event.name +
							" " +
							event.getDay() +
							" [" +
							event.getDate() +
							"]"}
					</MenuItem>
				))}
			</TextField>
			<Map eventId={eventId} />
		</Container>
	);
}
export function Map({ eventId }: { eventId: number }) {
	const [theater, setTheater] = useState();

	const [reload, setReload] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			// // console.log("Footer Rendered " + reload);
			setReload(reload + 1);
		}, 30000);
	}, [reload]);

	useEffect(() => {
		if (eventId != 0)
			makeRequest({
				endpoint: `/api/seats/get_admin_seats`,
				data: {
					event_id: eventId,
				},
				onResolve: (response: any) => {
					// console.log(response.data);
					setTheater(response.data);
				},
				requestMessage: {
					pending: "Loading Theater..",
					error: "Something went wrong!, please contact us!",
				},
			});
		// // console.log(seats);
	}, [reload, eventId]);

	const theater_id = (theater as any)?.theater_id;
	const adminView = true;
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				m: "0",
				p: 0,
				pt: { xs: 0, md: "90px" },
				// mb: "100px",
				width: "100%",
				// maxWidth: "1000px",
			}}
		>
			{eventId ? (
				<>
					<Box sx={{ display: "flex", justifyContent: "center" }}>
						<Box
							sx={{
								display: "flex",
								m: 2,
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Box
								sx={{
									width: 10,
									height: 10,
									p: "5px",
									m: "5px",
									bgcolor: "blue",
									borderRadius: "0 0 100% 100%",
								}}
							></Box>
							<Box>Checked In</Box>
						</Box>
						<Box
							sx={{
								display: "flex",
								m: 2,
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Box
								sx={{
									width: 10,
									height: 10,
									p: "5px",
									m: "5px",
									bgcolor: "Green",
									borderRadius: "0 0 100% 100%",
								}}
							></Box>
							<Box>Not Checked In Yet</Box>
						</Box>
						<Box
							sx={{
								display: "flex",
								m: 2,
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Box
								sx={{
									width: 10,
									height: 10,
									p: "5px",
									m: "5px",
									bgcolor: "Orange",
									borderRadius: "0 0 100% 100%",
								}}
							></Box>
							<Box>Empty Seat</Box>
						</Box>
					</Box>
					<TheaterOfficial
						container_id={"map-container"}
						theater_id={theater_id}
						theater_css={(theater as any)?.theater_css}
					>
						{theater &&
							(theater as any)?.areas.map((area: any) => (
								<Area
									key={area.area_name}
									area_type={area.area_type}
									area_css={area.area_css}
									area_name={area.area_name}
								>
									{area.area_type == AreaType.STANDING ? (
										<Button
											sx={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												m: 2,
												rotate: "180deg",

												width: "100%",
												bgcolor: "orange",
												height: "100px",
											}}
										>
											Standing Area{" "}
											{adminView ? (
												<>
													{area.reserved_seat_count}{" "}
													reserved out of{" "}
													{area.seat_count}
												</>
											) : (
												<></>
											)}
										</Button>
									) : area.area_type == AreaType.STAGE ? (
										<>
											المسرح
											<br></br>
											Stage
										</>
									) : (
										// ) : (
										<>
											{area.rows.map((row: any) => (
												<TheaterRow
													area_type={area.area_type}
													row_id={row.row_name}
												>
													{row.groups.map(
														(
															group: any,
															index: number
														) => (
															<TheaterRowGroup
																area_type={
																	area.area_type
																}
																group_number={
																	index
																}
																row_id={
																	row.row_name
																}
															>
																{group?.seats?.map(
																	(
																		seat: any
																	) => (
																		<TheaterSeat2
																			seat={
																				seat
																			}
																		/>
																	)
																)}
															</TheaterRowGroup>
														)
													)}
												</TheaterRow>
											))}
										</>
									)}
								</Area>
							))}
					</TheaterOfficial>
				</>
			) : (
				<></>
			)}
		</Box>
	);
}

export function FormulatorMap({ eventId }: { eventId: number }) {
	const [theater, setTheater] = useState();

	const [reload, setReload] = useState(0);

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		// // console.log("Footer Rendered " + reload);
	// 		setReload(reload + 1);
	// 	}, 30000);
	// }, [reload]);

	useEffect(() => {
		makeRequest({
			endpoint: `/api/seats/get`,
			data: {
				event_id: eventId,
			},
			onResolve: (response: any) => {
				// console.log(response.data);
				setTheater(response.data);
			},
			requestMessage: {
				pending: "Loading Theater..",
				error: "Something went wrong!, please contact us!",
			},
		});
	}, [reload, eventId]);
	const theater_id = (theater as any)?.theater_id;
	const adminView = false;

	return (
		<Box
			id="map-container"
			sx={{
				width: "100%",
				// width: "500px",
				// bgcolor: "blue",
				// height: "100px",
				// display: "flex",
				// flexGrow: 1,
				// flexDirection: "column",
				// m: "0",
				// p: 0,
				// // pt: { xs: 0, md: "90px" },
				// // mb: "100px",
				// width: "100%",
				// // maxWidth: "1000px",
			}}
		>
			<Box
			//  sx={{ width: "100%" }}
			>
				{theater && (
					<>
						<Typography variant="caption">
							اختار فئة المقعد من هون
						</Typography>

						<ChooseSeatTypeToggleButton />
						<Typography variant="caption">المسرح فوق</Typography>
					</>
				)}
				{/* <Box sx={{ display: "flex", justifyContent: "center" }}>
						<Box
							sx={{
								display: "flex",
								m: 2,
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Box
								sx={{
									width: 10,
									height: 10,
									p: "5px",
									m: "5px",
									bgcolor: "blue",
									borderRadius: "0 0 100% 100%",
								}}
							></Box>
							<Box>Checked In</Box>
						</Box>
						<Box
							sx={{
								display: "flex",
								m: 2,
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Box
								sx={{
									width: 10,
									height: 10,
									p: "5px",
									m: "5px",
									bgcolor: "Green",
									borderRadius: "0 0 100% 100%",
								}}
							></Box>
							<Box>Not Checked In Yet</Box>
						</Box>
						<Box
							sx={{
								display: "flex",
								m: 2,
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Box
								sx={{
									width: 10,
									height: 10,
									p: "5px",
									m: "5px",
									bgcolor: "Orange",
									borderRadius: "0 0 100% 100%",
								}}
							></Box>
							<Box>Empty Seat</Box>
						</Box>
					</Box> */}
				{/* <Area theater_id={theater_id}>
						{seats.map((row: any) => (
							<TheaterRow row_id={row.seat_row}>
								{row.seat_groups.map((group: any) => (
									<TheaterRowGroup>
										{group.seats.map((seat: any) => (
											<FormTheaterSeat seat={seat} />
										))}
									</TheaterRowGroup>
								))}
							</TheaterRow>
						))}
					</Area> */}

				<TheaterOfficial
					margin={40}
					container_id={"registration-container"}
					theater_id={theater_id}
					theater_css={(theater as any)?.theater_css}
				>
					{theater &&
						(theater as any)?.areas.map((area: any) => (
							<Area
								key={area.area_name}
								area_type={area.area_type}
								area_css={area.area_css}
								area_name={area.area_name}
							>
								{area.area_type == AreaType.STANDING ? (
									<FormTheaterStandingSeat />
								) : area.area_type == AreaType.STAGE ? (
									<Box sx={{ color: "white" }}>
										المسرح
										<br></br>
										Stage
									</Box>
								) : (
									// ) : (
									<>
										{area.rows.map((row: any) => (
											<TheaterRow
												area_type={area.area_type}
												row_id={row.row_name}
											>
												{row.groups.map(
													(
														group: any,
														index: number
													) => (
														<TheaterRowGroup
															area_type={
																area.area_type
															}
															group_number={index}
															row_id={
																row.row_name
															}
														>
															{group?.seats?.map(
																(seat: any) => (
																	<FormTheaterSeat
																		seat={
																			seat
																		}
																	/>
																)
															)}
														</TheaterRowGroup>
													)
												)}
											</TheaterRow>
										))}
									</>
								)}
							</Area>
						))}
				</TheaterOfficial>
			</Box>
		</Box>
	);
}
