import { Box } from "@mui/material";
import { Attendee, useAttendee } from "../../hooks/contexts/UseAttendeeContext";
import { useSeats } from "../../hooks/contexts/useSeatsContext";
import { AreaType } from "./AreaType";

export function Area(props: any) {
	return (
		<Box
			className={`area ${props.area_name}`}
			sx={{
				"& .seat": {
					rotate: `${
						props.area_type == AreaType.DOWN
							? 180
							: props.area_type == AreaType.LEFT
							? 270
							: props.area_type == AreaType.RIGHT
							? 90
							: props.area_type == AreaType.UP
							? 0
							: 180
					}deg`,
				},

				display:
					props.area_type == AreaType.LEFT ||
					props.area_type == AreaType.RIGHT
						? "flex"
						: "grid",

				height: "fit-content",
				width: "fit-content",

				flexDirection:
					props.area_type == AreaType.LEFT ? "row-reverse" : "unset",

				...JSON.parse(props.area_css),
			}}
		>
			{props.children}
			{/* {props.children.length ? (
				<Box
					className={
						attendee?.number_of_tickets === selectedSeats?.length
							? "hide"
							: ""
					}
					sx={{{/* {props.children.length ? (
				<Box
					className={
						attendee?.number_of_tickets === selectedSeats?.length
							? "hide"
							: ""
					}
					sx={{
						bgcolor: "#f68a26",
						height: "100px",
						borderRadius: "40% 40% 0 0",
						width: "80%",
						margin: "auto",
						marginTop: "30px",
						marginBottom: "50px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						color: "white",
						fontWeight: "bold",
						opacity: 1,

						transition: "opacity 0.5s, background-color .3s ",
						"&.hide": {
							opacity: 0.1,
						},
					}}
				>
						bgcolor: "#f68a26",
						height: "100px",
						borderRadius: "40% 40% 0 0",
						width: "80%",
						margin: "auto",
						marginTop: "30px",
						marginBottom: "50px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						color: "white",
						fontWeight: "bold",
						opacity: 1,

						transition: "opacity 0.5s, background-color .3s ",
						"&.hide": {
							opacity: 0.1,
						},
					}}
				>
					المسرح
				</Box>
			) : (
				<></>
			)} */}
		</Box>
	);
}
