import { ChangeEventHandler, Dispatch, ReactElement } from "react";

export default class InputAttributes {
	inputRef: any;
	type: string;
	label: string;
	description: string;
	name: string;
	required: boolean;
	placeholder: any;
	multiPartInput: any;
	onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | any;

	setValueHook:
		| ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
		| Dispatch<any>
		| any;
	setSectionValueHook:
		| ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
		| Dispatch<any>
		| any;
	endIcon: Array<ReactElement>;
	value: any;
	defaultValue: any;
	min: any;
	max: any;

	constructor({
		inputRef = null,
		type = "text",
		label = "input",
		description = "",
		multiPartInput = [],
		name = "",
		required = false,
		placeholder = "",
		onChange = () => {},
		setValueHook = () => {},
		setSectionValueHook = () => {},
		endIcon = [],
		value = "",
		defaultValue = "",
		min = "",
		max = "",
	}: {
		inputRef?: any;
		type?:
			| "number"
			| "password"
			| "url"
			| "text"
			| "tel"
			| "email"
			| "date"
			| "time"
			| "month"
			| "week"
			| "datetime-local"
			| "color"
			| "file"
			| "textarea"
			| "select"
			| "checkbox"
			| "switch"
			| "range"
			| "radio";
		label?: string;
		description?: string;
		multiPartInput?: any;
		name?: string;
		placeholder?: any;
		required?: boolean;
		onChange?:
			| ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
			| any;
		setValueHook?:
			| ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
			| Dispatch<any>
			| any;
		setSectionValueHook?:
			| ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
			| Dispatch<any>
			| any;
		endIcon?: Array<ReactElement>;
		value?: any;
		defaultValue?: any;
		min?: any;
		max?: any;
	}) {
		this.inputRef = inputRef;
		this.type = type;
		this.label = label;
		this.description = description;
		this.name = name;
		this.multiPartInput = multiPartInput;
		this.required = required;

		this.placeholder = placeholder;
		this.onChange = onChange;
		this.setValueHook = setValueHook;
		this.setSectionValueHook = setSectionValueHook;
		this.endIcon = endIcon;
		this.value = value;
		this.defaultValue = defaultValue;
		this.min = min;
		this.max = max;
	}
}
