import {
	ConfirmationNumber,
	ContentCopy,
	Delete,
	WhatsApp,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Drawer,
	MenuItem,
	TextField,
} from "@mui/material";
import {
	DataGrid,
	GridActionsCellItem,
	GridColDef,
	GridEventListener,
	GridRowEditStopReasons,
	GridRowId,
	GridRowModel,
	GridRowModes,
	GridRowModesModel,
	GridRowsProp,
	GridToolbarContainer,
} from "@mui/x-data-grid";
import { randomId, randomInt } from "@mui/x-data-grid-generator";
import { useEffect, useState } from "react";
import { TicketClass } from "../../components/stepper/tickets-step";
import { Attendee } from "../../hooks/contexts/UseAttendeeContext";
import { makeRequest } from "../../utility/request";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Event } from "../../hooks/contexts/UseEventContext";
// import { randomUUID } from "crypto";

function DeleteButton({
	id,
	setRows,
}: {
	id: string;
	setRows: CallableFunction;
}) {
	const [open, setOpen] = useState<boolean>(false);

	const handleClose = () => setOpen(false);
	return (
		<Box>
			<Button
				onClick={() => setOpen(true)}
				sx={{
					".MuiButton-startIcon": {
						m: 0,
					},
				}}
				variant="contained"
				color="error"
				startIcon={<Delete />}
			></Button>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{" Are you sure you want to permanently delete this?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Do you want to continue?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						color="error"
						onClick={() => {
							makeRequest({
								endpoint: "/api/attendee/delete",
								data: {
									id: id,
								},

								requestMessage: {
									success: "Was Successfuly Deleted",
									pending: "Loading",
									error: "Something went wrong! 🤯",
								},
								onResolve: (response: any) => {
									setRows((rows: any) =>
										rows?.filter(
											(row: any) => row.id !== id
										)
									);
								},
							});
						}}
					>
						DELETE
					</Button>

					<Button variant="contained" onClick={() => handleClose()}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}

function DeleteTicketButton({
	id,
	setRows,
}: {
	id: string;
	setRows: CallableFunction;
}) {
	const [open, setOpen] = useState<boolean>(false);

	const handleClose = () => setOpen(false);
	return (
		<Box>
			<Button
				onClick={() => setOpen(true)}
				sx={{
					".MuiButton-startIcon": {
						m: 0,
					},
				}}
				variant="contained"
				color="error"
				startIcon={<Delete />}
			></Button>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{" Are you sure you want to permanently delete this?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Do you want to continue?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						color="error"
						onClick={() => {
							makeRequest({
								endpoint: "/api/ticket/delete",
								data: {
									id: id,
								},

								requestMessage: {
									success: "Was Successfuly Deleted",
									pending: "Loading",
									error: "Something went wrong! 🤯",
								},
								onResolve: (response: any) => {
									setRows((rows: any) =>
										rows?.filter(
											(row: any) => row.id !== id
										)
									);
								},
							});
						}}
					>
						DELETE
					</Button>

					<Button variant="contained" onClick={() => handleClose()}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}
// export function ManageTickets({
// 	attendee_id,
// 	already_reserved,
// }: {
// 	attendee_id: string;
// 	already_reserved: boolean;
// }) {
// 	const [open, setOpen] = useState<boolean>(false);
// 	const [tickets, setTickets] = useState<Array<TicketClass>>([]);

// 	// useEffect(() => {
// 	// 	if (open) {
// 	// 		makeRequest({
// 	// 			endpoint: `/api/ticket/get`,
// 	// 			data: {
// 	// 				attendee_id: attendee_id.toString(),
// 	// 			},

// 	// 			requestMessage: {
// 	// 				pending: "Promise is pending",
// 	// 				success: "Promise resolved 👌",
// 	// 				error: "Promise rejected 🤯",
// 	// 			},
// 	// 			onResolve: (response: any) => {
// 	// 				console.log(response.data);
// 	// 				setTickets(
// 	// 					response.data.map(
// 	// 						(ticket: any) => new TicketClass({ ...ticket })
// 	// 					)
// 	// 				);
// 	// 			},
// 	// 		});
// 	// 	}
// 	// }, [open]);

// 	return (
// 		<>
// 			<Button
// 				disabled={!already_reserved}
// 				variant="contained"
// 				color="success"
// 				target="_blank"
// 				href={`/admin/manage/tickets/${attendee_id}`}
// 				// onClick={() => {
// 				// 	setOpen(true);
// 				// }}
// 				startIcon={<ConfirmationNumber />}
// 			>
// 				View
// 			</Button>
// 			{/* <Drawer
// 				anchor={"right"}
// 				open={open}
// 				onClose={() => {
// 					setOpen(false);
// 				}}
// 			>
// 				<ManageTicketsGrid attendee_id={attendee_id} />
// 			</Drawer> */}
// 		</>
// 	);
// }

const types = ["Class A | فئة أ", "Class B | فئة ب", "Class C | فئة ج"];

const typesInverse: { [key: string]: number } = {
	"Class A | فئة أ": 1,
	"Class B | فئة ب": 2,
	"Class C | فئة ج": 3,
};

const paymentTypes = ["Not Paid | غير مدفوع", "Paid | مدفوع", "Invite | مدعو"];

const paymentTypesInverse: { [key: string]: number } = {
	"Not Paid | غير مدفوع": 0,
	"Paid | مدفوع": 1,
	"Invite | مدعو": 2,
};

interface EditToolbarProps {
	setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
	setRowModesModel: (
		newModel: (oldModel: GridRowModesModel) => GridRowModesModel
	) => void;
	setEventId: CallableFunction;
	event_id: number;
}

function EditToolbar(props: EditToolbarProps) {
	const { setRows, setRowModesModel, setEventId, event_id } = props;

	const handleClick = () => {
		const id = randomId();
		setRows((oldRows) => [
			...oldRows,
			{ id, name: "", age: "", isNew: true },
		]);
		setRowModesModel((oldModel) => ({
			...oldModel,
			[id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
		}));
	};

	const [eventList, setEventList] = useState<Array<Event>>([]);

	useEffect(() => {
		makeRequest({
			endpoint: `/api/event/get_all`,
			data: {},

			requestMessage: {
				pending: "Loading",
				error: "Something went wrong! please contact us!",
			},
			onResolve: (response: any) => {
				setEventList(
					response.data.map(
						(eventObj: any) => new Event({ ...eventObj })
					)
				);
			},
		});
	}, []);
	return (
		<GridToolbarContainer>
			<TextField
				defaultValue={0}
				select
				sx={{ width: "100%" }}
				onChange={(value) => {
					setEventId(value.target.value);
				}}
			>
				<MenuItem value={0}>اختر الحفلة</MenuItem>

				{eventList.map((event) => (
					<MenuItem value={event?.id}>
						{"حفلة " +
							event.name +
							" " +
							event.getDay() +
							" [" +
							event.getDate() +
							"]"}
					</MenuItem>
				))}
			</TextField>
		</GridToolbarContainer>
	);
}

export function ManageTickets() {
	const [event_id, setEventId] = useState(0);

	const [reload, setReload] = useState(0);

	const [rows, setRows] = useState<GridRowsProp>([]);
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

	const types = ["Class A | فئة أ", "Class B | فئة ب", "Class C | فئة ج"];

	const typesInverse: { [key: string]: number } = {
		"Class A | فئة أ": 1,
		"Class B | فئة ب": 2,
		"Class C | فئة ج": 3,
	};

	useEffect(() => {
		makeRequest({
			endpoint: `/api/ticket/get_event`,
			data: {
				event_id: event_id.toString(),
			},

			requestMessage: {
				pending: "Promise is pending",
				success: "Promise resolved 👌",
				error: "Promise rejected 🤯",
			},
			onResolve: (response: any) => {
				// console.log(response.data);
				setRows(
					response.data.map((row: any, index: number) => {
						// console.log(row);
						return new TicketClass({
							...row,
							type: types[row.type - 1],
						});
					})
				);
			},
		});
	}, [event_id, reload]);

	const handleRowEditStop: GridEventListener<"rowEditStop"> = (
		params,
		event
	) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true;
		}
	};

	const handleEditClick = (id: GridRowId) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.Edit },
		});
	};

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View },
		});
	};

	const handleHasPaidClick = (id: GridRowId) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View },
		});
	};

	const handleDeleteClick = (id: GridRowId) => () => {
		setRows(rows.filter((row) => row.id !== id));
	};

	const handleCancelClick = (id: GridRowId) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		});

		const editedRow = rows.find((row) => row.id === id);
		if (editedRow!.isNew) {
			setRows(rows.filter((row) => row.id !== id));
		}
	};

	const processRowUpdate = (newRow: GridRowModel) => {
		const updatedRow = { ...newRow, isNew: false };

		// makeRequest({
		// 	endpoint: "/api/attendee/update",
		// 	data:{
		// 		...newRow,
		// 		ticket_type: typesInverse[newRow.ticket_type],
		// 		payment_status : paymentTypesInverse[newRow.payment_status]
		// 	},

		// 	requestMessage: {
		// 		success:"Was Successfuly Updated",
		// 		pending: "Updating",
		// 		error: "Something went wrong! 🤯",
		// 	},
		// 	onResolve: (response:any) => {
		// 			setRows(
		// 				rows.map((row) =>
		// 					row.id === newRow.id ? updatedRow : row
		// 				)
		// 			);

		// 	},
		// });

		return updatedRow;
	};

	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel);
	};

	// ticket_id: number;
	// event_id: number;
	// type: SeatType;

	// seat_row: string;
	// seat_column: string;

	// ticketTypes = []
	const columns: GridColDef[] = [
		{
			field: "id",
			headerName: "ID\nID بالعربي",
			width: 280,
			editable: false,
			headerAlign: "center",
		},
		{
			field: "first_name",
			headerName: "first Name\nاسم الاول",
			// width: 180,
			minWidth: 150,
			editable: true,
		},
		{
			field: "type",
			headerName: "Ticket Type\nنوع التذاكر",
			type: "singleSelect",
			width: 150,
			align: "center",

			valueOptions: types,
			headerAlign: "center",
			editable: true,
		},
		{
			field: "seat_row",
			headerName: "Row\nالصف",
			type: "text",

			minWidth: 250,
			editable: true,
		},
		{
			field: "seat_column",
			headerName: "Column\nالعمود",
			// width: 180,
			type: "text",

			minWidth: 150,
			editable: true,
		},
		{
			field: "payment_status",
			type: "singleSelect",
			valueOptions: paymentTypes,
			align: "center",
			headerAlign: "center",
			headerName: "Payment Status\nحالة الدفع",
			width: 150,
			editable: true,
		},
		{
			field: "has_checked_in",
			type: "boolean",
			// valueOptions: ha,
			align: "center",
			headerAlign: "center",
			headerName: "Has Checked In",
			width: 150,
			editable: true,
		},
		{
			field: "button1",
			headerName: "Check In",
			type: "actions",

			align: "center",
			headerAlign: "center",
			width: 150,
			sortable: false,
			editable: false,
			renderCell: ({ row }) => {
				return (
					<Button
						// href={`https://api.whatsapp.com/send/?phone=${row.phone_number}&text=${requestPaymentWhatsAppMessage}`}
						// target="_blank"
						onClick={() => {
							makeRequest({
								endpoint: "/api/ticket/checkin",
								data: {
									ids: [row.id],
								},
								onResolve: (response: any) => {
									setReload(reload + 1);
									// row.has_checked_in = 1
								},
							});
						}}
						variant="contained"
						sx={{ m: 1 }}
						color="success"
						// startIcon={<WhatsApp />}
						disabled={row.has_checked_in}
					>
						Check In
					</Button>
				);
			},
		},
		{
			field: "button2",
			headerName: "Uncheck In",
			type: "actions",

			align: "center",
			headerAlign: "center",
			width: 150,
			sortable: false,
			editable: false,
			renderCell: ({ row }) => {
				return (
					<Button
						// href={`https://api.whatsapp.com/send/?phone=${row.phone_number}&text=${requestPaymentWhatsAppMessage}`}
						// target="_blank"

						onClick={() => {
							makeRequest({
								endpoint: "/api/ticket/uncheckin",
								data: {
									ids: [row.id],
								},
								onResolve: (response: any) => {
									setReload(reload + 1);
									// row.has_checked_in = 1
								},
							});
						}}
						variant="contained"
						sx={{ m: 1 }}
						color="success"
						// startIcon={<WhatsApp />}
						disabled={!row.has_checked_in}
					>
						Uncheck In
					</Button>
				);
			},
		},
		{
			field: "actions",
			type: "actions",
			headerName: "Actions\nActions بالعربي",
			width: 100,
			cellClassName: "actions",
			getActions: ({ id }) => {
				const isInEditMode =
					rowModesModel[id]?.mode === GridRowModes.Edit;

				if (isInEditMode) {
					return [
						<GridActionsCellItem
							icon={<SaveIcon />}
							label="Save"
							sx={{
								color: "primary.main",
							}}
							onClick={handleSaveClick(id)}
						/>,
						<GridActionsCellItem
							icon={<CancelIcon />}
							label="Cancel"
							className="textPrimary"
							onClick={handleCancelClick(id)}
							color="inherit"
						/>,
					];
				}

				return [
					<GridActionsCellItem
						icon={<EditIcon />}
						label="Edit"
						className="textPrimary"
						onClick={handleEditClick(id)}
						color="inherit"
					/>,
				];
			},
		},
		{
			field: "button3",
			headerName: "Delete\nحذف",
			type: "actions",

			align: "center",
			headerAlign: "center",
			width: 100,
			sortable: false,
			editable: false,
			renderCell: ({ row }) => {
				return <DeleteTicketButton id={row.id} setRows={setRows} />;
			},
		},
	];

	return (
		<DataGrid
			key={"ticket" + event_id}
			sx={{
				bgcolor: "white",
				mt: "-5px",

				".MuiDataGrid-columnHeaderTitle": {
					whiteSpace: "pre-wrap",
					lineHeight: 1.5,
				},

				".MuiDataGrid-main": {
					margin: { xs: "0", md: "0 20px" },
				},

				".MuiDataGrid-row:has(.MuiDataGrid-cell--editing) .actions": {
					bgcolor: " #ffffff",
					position: "sticky",
					right: 0,
					boxShadow: "-3px 0px 1px 0px #b3b3b36b",
				},

				".MuiDataGrid-virtualScroller": {
					height: "calc(100vh - 260px)",
				},
				".MuiDataGrid-actionsCell": {
					gridGap: "20px",
				},
			}}
			rows={rows}
			columns={columns}
			editMode="row"
			// getRowHeight={() => "auto"}
			pageSizeOptions={[10, 20, 50, 100, 500]}
			initialState={{
				pagination: {
					paginationModel: {
						pageSize: 20,
					},
				},
				columns: {
					columnVisibilityModel: {
						// id: false,
						email: false,
					},
				},
			}}
			rowModesModel={rowModesModel}
			onRowModesModelChange={handleRowModesModelChange}
			onRowEditStop={handleRowEditStop}
			processRowUpdate={processRowUpdate}
			slots={{
				toolbar: EditToolbar,
			}}
			slotProps={{
				toolbar: { setRows, setRowModesModel, setEventId, event_id },
			}}
		/>
	);
}
