import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SeatType } from "../../components/stepper/stepper";
import { makeRequest } from "../../utility/request";
import AttendeeContext from "./AttendeeContext";

enum PaymentStatus {
	NO_PAID = 0,
	PAID = 1,
	INVITE = 2,
}

export class Attendee {
	id: string | number;
	first_name: string;
	last_name: string;
	email: string;
	event_id: number;
	phone_number: string;
	number_of_tickets: number;
	ticket_type: SeatType;
	already_reserved: boolean;
	payment_status: PaymentStatus;
	priority: number;
	note: string;

	constructor({
		id,
		first_name,
		last_name,
		email,
		event_id,
		phone_number,
		number_of_tickets,
		ticket_type,
		already_reserved,
		payment_status,
		priority,
		note,
	}: {
		id: string | number;
		first_name: string;
		last_name: string;
		email: string;
		event_id: number;

		phone_number: string;
		number_of_tickets: number;
		ticket_type: SeatType;
		already_reserved: boolean;
		payment_status: PaymentStatus;
		priority: number;
		note: string;
	}) {
		this.id = id;
		this.first_name = first_name;
		this.last_name = last_name;
		this.email = email;
		this.event_id = event_id;
		this.phone_number = phone_number;
		this.number_of_tickets = number_of_tickets;
		this.ticket_type = ticket_type;
		this.already_reserved = already_reserved;
		this.payment_status = payment_status;
		this.priority = priority;
		this.note = note;
	}
}
export function useAttendeeContext() {
	const [attendee, setAttendee] = useState<Attendee | null>(null);

	let { attendee_id } = useParams();

	useEffect(() => {
		makeRequest({
			endpoint: `/api/attendee/get`,
			data: {
				id: attendee_id,
			},

			requestMessage: {
				pending: "Loading",
				error: "Something went wrong! please contact us!",
			},
			onResolve: (response: any) => {
				if (response.data != null) {
					setAttendee(new Attendee({ ...response.data }));
				} else {
					setAttendee(
						new Attendee({ ...{ ...response.data }, id: -1 })
					);
				}
			},
		});
	}, []);

	return {
		AttendeeContext: AttendeeContext,
		attendee: attendee,
		setAttendee: setAttendee,
	};
}

export function useAttendee() {
	return useContext(AttendeeContext.Context);
}
