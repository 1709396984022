import { Add, Cancel, Close, ExitToAppSharp } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	MenuItem,
	TextField,
} from "@mui/material";
import { useState } from "react";

export function AddNewAttendee({ event_id }: { event_id: number }) {
	const events = [
		"حفلة الست يوم الخميس",
		"حفلة الست يوم الجمعة",
		"حفلة منوعات يوم الخميس",
	];

	const [open, setOpen] = useState<boolean>(false);

	const handleClose = () => setOpen(false);
	return (
		<Box>
			<Button
				disabled={!event_id || true}
				onClick={() => setOpen(true)}
				sx={{
					".MuiButton-startIcon": {
						m: 0,
					},
				}}
				variant="contained"
				// color="error"
				startIcon={<Add />}
			>
				Add
			</Button>

			<Dialog
				fullWidth={true}
				maxWidth="md"
				PaperProps={{ sx: { m: 0, p: 0, width: "100%" } }}
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle
					id="alert-dialog-title"
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					{`Add new attendee to \n ${events[event_id - 1]}`}

					<IconButton onClick={() => handleClose()}>
						<Close />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					{/* <DialogContentText id="alert-dialog-description"> */}

					<Box
						component={"form"}
						sx={{
							// p: "20px 0",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexWrap: { md: "nowrap", xs: "wrap" },
							}}
						>
							<TextField
								sx={{ m: 1, width: "100%", minWidth: 150 }}
								name="first_name"
								required
								label="First name | لاسم الاول"
							/>

							<TextField
								sx={{ m: 1, width: "100%", minWidth: 150 }}
								name="last_name"
								label="Last name | اسم العائلة"
							/>
						</Box>
						<Box sx={{ display: "flex", width: "100%" }}>
							<TextField
								sx={{ width: "100%", m: 1 }}
								name="email"
								type="email"
								label="Email | البريد الاكتروني"
							/>
						</Box>
						<Box sx={{ display: "flex", width: "100%" }}>
							<TextField
								sx={{ width: "100%", m: 1 }}
								name="phone_number"
								type="tel"
								label="Phone Number | رقم التلفون"
							/>
						</Box>

						<Box
							sx={{
								display: "flex",
								flexWrap: { md: "nowrap", xs: "wrap" },
							}}
						>
							<TextField
								label="Ticket Type | نوع التذاكر"
								// defaultValue={0}
								required
								select
								sx={{ m: 1, width: "100%" }}
								onChange={(value) => {
									// setEventId(value.target.value);
								}}
							>
								<MenuItem value={1}>Class A | فئة أ</MenuItem>
								<MenuItem value={2}>Class B | فئة ب</MenuItem>
								<MenuItem value={3}>Class C | فئة ج</MenuItem>
							</TextField>
							<TextField
								sx={{ m: 1, width: "100%" }}
								type="number"
								inputProps={{
									min: "1",
								}}
								name="number_of_tickets"
								required
								label="Number of Tickets | عدد التذاكر"
							/>
						</Box>
						<Box sx={{ display: "flex" }}>
							<TextField
								label="Payment Status | حالة الدفع"
								defaultValue={0}
								select
								sx={{ m: 1, width: "100%" }}
								onChange={(value) => {
									// setEventId(value.target.value);
								}}
							>
								<MenuItem value={0}>
									Not Paid | غير مدفوع
								</MenuItem>
								<MenuItem value={1}>Paid | مدفوع </MenuItem>
								<MenuItem value={2}>Invite | مدعو</MenuItem>
							</TextField>
						</Box>
						<Box sx={{ display: "flex", width: "100%" }}>
							<TextField
								sx={{ width: "100%", m: 1 }}
								multiline
								minRows={3}
								label="Note | ملاحظات"
							/>
						</Box>
						<Button
							variant="contained"
							// color="error"
							type="submit"
							sx={{ m: 1, width: "100%" }}
							onClick={(e) => {
								e.preventDefault();
								// makeRequest({
								// 	endpoint: "/api/attendee/delete",
								// 	data: {
								// 		id: id,
								// 	},
								// 	requestMessage: {
								// 		success: "Was Successfuly Deleted",
								// 		pending: "Loading",
								// 		error: "Something went wrong! 🤯",
								// 	},
								// 	onResolve: (response: any) => {
								// 		setRows((rows: any) =>
								// 			rows?.filter(
								// 				(row: any) => row.id !== id
								// 			)
								// 		);
								// 	},
								// });
							}}
						>
							Add | إضافة
						</Button>
					</Box>
				</DialogContent>
				<DialogActions></DialogActions>
			</Dialog>
		</Box>
	);
}
