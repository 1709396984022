import { FormulatorSectionTypes } from "./FormaulatorSectionTypes";
import InputAttributes from "./InputAttribures";

export default class FormulatorSection {
	rows: Array<Array<InputAttributes> | JSX.Element> = [];

	constructor(rows: Array<Array<InputAttributes> | JSX.Element>) {
		this.rows = rows;
	}
}
